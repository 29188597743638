import React from 'react';
import { useForm, useWatch } from "react-hook-form";
import { FormatDateShort, headerRowStyle } from './Header';

// just like an input element, except w/ additional label prop
export const FormInput = ( props ) => {
  const {label, register, ...inputProps} = props;

  return (
    <label className="InputField__Field-sc-19cgljf-0 jzgoBR">
    <span className="FormLabel-sc-14kkg44-2 kVDPSv">
      <span>{label}</span>
    </span>
    <div className="InputField__InputContainer-sc-19cgljf-2 gjMAnv">
      <input 
        {...inputProps}
        {...(register && register(props.name))}
        className="InputField__Input-sc-19cgljf-6 ihLOjl"
      />
      <div className="InputField__FakeInput-sc-19cgljf-1 isLhOw"></div>
    </div>
  </label>
  );
};

export const HookForm = ( {submitHandler, details, Inputs} ) => {
  const { register, control, handleSubmit, setValue } = useForm();

  return (
    <form 
      noValidate 
      onSubmit={handleSubmit(submitHandler)}
      className="header-container active" 
      {...(!submitHandler ? {inert: 'true', className: "header-container inert" } : {})}
    >
      <Inputs details={details} register={register} control={control} setValue={setValue}/>
      <div style={{ ...headerRowStyle, justifyContent: 'center' }}>
      <button style={{ margin: '2px 0px', width: '64px', height: '24px' }} type="submit">Search</button>
      </div>
    </form>
  );
};