import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Divider } from '../Divider';
import ItineraryList from '../ItineraryList';
import { FormatDateShort, headerRowStyle } from '../Header';
import * as FormInputs from '../FlightSearchAdder';
import { AirportsDataList } from './AirportsDataList'
import { FormInput, HookForm } from '../HookFormInputs';

export const InputBody = ({ details }) => {
  const [isFlex, setIsFlex] = useState(false);
  //1st div is frankensteind
  return (
  <>
  <div className="header-container" style={{ ...headerRowStyle, colorScheme: 'light dark' }}>
  <meter></meter>

  <label style={{WebkitUserSelect: 'none', marginLeft: 'auto', fontSize: '16px'}}>
        Flexible?
        <input type="checkbox" switch="true" onChange={(e) => setIsFlex(e.target.checked)}
          style={{ margin: '0px 2px', height: '18px', width: '30px', verticalAlign: 'middle' }}
        />
    </label>
  </div>
  {isFlex &&
    <div className="header-container active" style={{...headerRowStyle}}>
    <div style={{display: 'inline-flex',flex: 'auto',alignItems: 'center',width: '50%'}}>
    <label style={{WebkitUserSelect: 'none', marginLeft: 'auto', fontSize: '16px'}}>
    <input type="radio" name="options" value="7"/>1 Week
    <input type="radio" name="options" value="30"/>1 Month
    <input type="radio" name="options" value="365"/>1 Year
    </label>
    </div>
    <div style={{display: 'inline-flex',flex: 'auto', width: '25%'}}>
    <FormInputs.DateInput 
        style={{width: '80%', marginLeft: 'auto'}}
        //register={register}
        id="end_date"
        name="end_date"
        label="Till?"
        defaultValue={details?.end_date
                      ? moment(details.end_date, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD')
                      : '' }
      />
    </div>
    </div>
    }
  </>
)};

export const ResSearchInputs = ( {register, control, details, setValue} ) => {

  return (
    <>
    <div className="header-container active" style={{...headerRowStyle}}>
    <FormInputs.FormInputDatalist name="cityCodeFrom" placeholder="Where?" defaultValue={details?.cityCodeFrom} 
                         register={register} control={control} setValue={setValue} DatalistComponent={AirportsDataList}
      />
    <FormInputs.DateInput 
        style={{marginLeft: 'auto', width: '20%'}} 
        //register={register}
        id="start_date"
        name="start_date"
        label="When?"
        defaultValue={details?.start_date
                      ? moment(details.start_date, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD')
                      : '' }
      />
    </div>
    <div className="header-container active" style={{...headerRowStyle}}>
      <select name="num_passengers" style={{flex: '1 1 0'}} defaultValue={details?.num_passengers} {...register('num_passengers')}>
        <option value="1">1 guest</option>
        <option value="2">2 guests</option>
        <option value="3">3 guests</option>
        <option value="4">4 guests</option>
      </select>
      <label>
        From
      <input 
        type="time"
        defaultValue="19:00"//{...inputProps}
        //{...(register && register(props.name))}
      />
      </label>
      <label>
        &
      <input type="time" defaultValue="21:00"/>
      </label>
    </div>
    </>
  )
}
export const Header = ( {submitHandler, details} ) => {

  return <HookForm submitHandler={true} details={{}} Inputs={ResSearchInputs}/>//FormInputs.FlightSearchInputs}/>
}

  /*  
  Problem: keyboard datalist does not take values on ios 17.6
  -current ios 17.6: show suggest, but user must type full code (or click arrow and select)
  -pair a text or search input w/ a dynamically updating select (optgroups?) 
  -showPicker() not supported on iOS but focus() works with a setTimeout
  -alternatively, use ionic typeahead/ react-bootstrap-typeahead or write custom using native selects + timed .focus()

  
  after/before work time toggle? /time of day filter

  HeaderSmall should really just be a Header w/ 1 line carved out at top
  -and subheader (hidden in stack) part of HeaderSmall?
  -1 form wrapping entire thing?
  -form should be switchable depending on planType
  */
//Old Stuff Below

const body = {
  overflow: 'auto',
  display: 'flex', //need otherwise padding gets scrolled
}

export const PlanBody = ( {trip, addlStyle} ) => {

  return (
    <div style={{ ...body, ...addlStyle }} onClick={(e) => e.stopPropagation()}>
      <ItineraryList items={trip.segments }/>
    </div>
  );
}

const box = {
  colorScheme: 'light dark',
  background: 'red', //remove for squared card look
  display: 'flex',
  flexDirection: 'column',
  minWidth: '280px', //375px
  maxHeight: '100svh',
  //margin: '0em auto', //adding a 1.805px margin - why?
}

//const colors = ['#2980B9','#27AE60','#007A96','#9C312D','#1D8F88']
const colors = ['#27AE60','#2980B9','#F0A050','#007A96','#1D8F88','#9C312D']

export const Plan = ( {trip, idx, body = <InputBody/>} ) => {

  const cardStyle = {
    background: colors[idx % 6] || 'light-dark(LightGray, DarkGray)', //Dark mode
    borderTop: `1px solid color-mix(in srgb, ${colors[idx % 6]} 75%, black)`,
    boxShadow: '0px 3px 10px',
    borderRadius: '12px',
    maxHeight: '650px', //should be constrained to <= CardContainer H
  }

  return (
      //box needs explicit height for overflow to work
      //but padding is thrown off by box height in card height
      <div style={{...box, ...cardStyle}}>
      
        <Header trip={trip} addlStyle={{padding: '12px 12px 6px'}}/>
        <Divider/>

        {/*<PlanBody trip={trip} addlStyle={{padding: '0px 12px 12px'}}/>*/}
        <div onClick={(e) => e.stopPropagation()}
          style={
            { 
              overflow: 'scroll',
              display: 'flex', //need otherwise padding gets scrolled
              padding: '0px 12px 12px' //...bodyStyle 
            }
          } 
        >
          {body}
          {/*<ItineraryList items={trip.segments }/>*/}
        </div>
        
      </div>
  );
}
/*
Decision:
1. Compose PlanTypes in Plan, passing in data, body, styles
2. Switch within Plan, getting data, body, styles?
Style examples
-cardStyles
-header type
-nested padding


Ideally Plan can be reused anywhere and bundle header, divider, body, etc.
To that end, it should have a base set of styles
as well as support "addlStyle", which allows for optional changes:
-padding
-height

Ideally we'd move the addlStyles in Card.js out of there and into here
...but that presents flex problems as it drops a div in between the card and components
*/

