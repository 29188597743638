import React, { useEffect, useState } from "react";
import moment from 'moment'
import { PinButton, PinButtonFilled, PindButton } from './components/PinButton';
import Itinerary, {
  ItinerarySegment,
  ItineraryStatus,
  ItineraryBadgeList,
  ItinerarySegmentStop,
  ItinerarySegmentDetail,
} from "@kiwicom/orbit-components/lib/Itinerary";
import Tile from "@kiwicom/orbit-components/lib/Tile";
import Badge from "@kiwicom/orbit-components/lib/Badge";
import BadgeList from "@kiwicom/orbit-components/lib/BadgeList";
import * as Icons from "@kiwicom/orbit-components/icons";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import Heading from "@kiwicom/orbit-components/lib/Heading";
import CountryFlag from "@kiwicom/orbit-components/lib/CountryFlag";
import Text from "@kiwicom/orbit-components/lib/Text";
import Modal, {ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";

const HostNameLink = ( {url} ) => {
    const hostname = new URL(url).hostname
    return (
      <a href={url}>{hostname}</a>
    )};

//still needs to be put in a tile for proper layout
const SegmentHeader = ( {title, meta, countryToCode} ) => (
    <Stack align="center" direction="row" justify="between" shrink>
      <Stack direction="column" shrink spacing="none">
        <Stack align="center" direction="row" spacing="XSmall">
          {/*title3 to match "title" font size, as="h4" seems to do nothing?*/}
          <Heading type="title4">{title}</Heading>
          {countryToCode && <CountryFlag code={countryToCode} />}
        </Stack>
      </Stack>
        <Stack align="center" basis="0%"><Badge type="info">{meta}</Badge>
      </Stack>
    </Stack>
    );

const SegmentTile = ( {icon, title, meta, body, countryToCode, onClick} ) => { //BaseSegment

  return (
    <div style={{margin: '1px 0vh 0vh'}}>
      <Tile 
        onClick={onClick}
        expandable={!onClick} //expanded
        noPadding={true}
        icon={icon}
        header={
          <SegmentHeader 
            title={title}
            meta={meta}
            countryToCode={countryToCode ?? ''}
          />}
      >
      {body}
      </Tile>
    </div>
    );
}

function getCarriers(airlines) {
  const carriers = [];
  //console.log(airlines instanceof Array); //=True but forEach still fails
  //https://stackoverflow.com/questions/31096596/why-is-foreach-not-a-function-for-this-object
  airlines && Object.keys(airlines).forEach(function (key){
      carriers.push({code: airlines[key]});
  });

  return carriers;
}

function getNumStops(routeLength) {
  if (routeLength === 1) {
    return "Direct"
  }
  if (routeLength === 2) {
    return (routeLength - 1) + " stop" 
  }
  if (routeLength > 2) {
    return (routeLength - 1) + " stops"
  }
}

export const FlightSegment = ( {item} ) => (
    <ItinerarySegment spaceAfter="none">
          <ItinerarySegmentStop
            city={item.cityFrom}
            station={item.flyFrom}
            date={moment(item.local_departure, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
            time={moment(item.local_departure, "YYYY-MM-DD hh:mm").format("h:mm A")}
          />
          <ItinerarySegmentDetail
            duration={moment("1900-01-01").startOf('day').seconds(item.duration.total || item.duration).format("h[h] m[m]")}
            summary={
              <div>
              <Badge //do multiple carries need multiple badges?
                carriers={getCarriers(item.airlines)}/*{[
                  {
                    code: item.airlines[0],
                    name: "Ryanair",
                  },
                  {
                    code: item.airlines[1],
                    name: "Ryanair2",
                  },
                ]}*/
              >
                {getNumStops(item.route.length)}
              </Badge>
              {!!item.link && <Badge><a href={item.link}>${item.fare.adults}</a></Badge>}
              {/* {<Badge><a href={item.link}>{item.route[0].airline + item.route[0].flight_no}</a></Badge>} */}
              </div>
            }
            /*content={[
              {
                title: "Connection Info",
                items: [
                  {
                    icon: <Icons.Airplane size="small" />,
                    name: "Carrier",
                    value: "Ryanair",
                  },
                  {
                    icon: <Icons.InformationCircle size="small" />,
                    name: "Connection number",
                    value: "RA 8345",
                  },
                ],
              },
              {
                title: "Seating Info",
                items: [
                  {
                    icon: <Icons.Wifi size="small" />,
                    name: "Wi-Fi on board",
                    value: "No",
                  },
                ],
              },
            ]}*/
          />
          <ItinerarySegmentStop
            city={item.cityTo}
            station={item.flyTo}
            date={moment(item.local_arrival, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
            time={moment(item.local_arrival, "YYYY-MM-DD hh:mm").format("h:mm A")}
          />
        </ItinerarySegment>
    );

export const FlightsSegment = ( {item} ) => (

  <ItinerarySegment spaceAfter="medium">  
  {
  item.route.map( (segment, i) => {
    const stopDt = (segment.takeoff || segment.local_departure)

    return <div key={i}>
    {
      "duration" in segment 
      ? 
      <ItinerarySegmentDetail
        duration={moment("1900-01-01 "+segment.duration, "YYYY-MM-DD hh:mm").format("h[h] m[m]")}
        summary={<Icons.Ticket size="small"/>}>
      </ItinerarySegmentDetail>
      : 
      <ItinerarySegmentStop
        city={segment.city}
        station={segment.station}
        date={moment(stopDt, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
        time={moment(stopDt, "YYYY-MM-DD hh:mm").format("h:mm A")}
      />
    }
    </div>
  })}

  </ItinerarySegment>
);

export const AccommodationSegment = ( {item} ) => (
  <SegmentTile
    icon={<Icons.Accommodation />}
    title={item.toStation}
    meta={moment(item.end_date, "YYYY-MM-DD").diff(moment(item.start_date, "YYYY-MM-DD"), 'days') + " Days/ Nights"}
    body={
        <Tile
          expandable
          title={item.price}
          description={<HostNameLink url={item.link}/>}
          icon={<img src={item.img} alt="IMG" width="64" height="64"/>}
        >
          Check In: {moment(item.start_date, "YYYY-MM-DD hh:mm").format("LT")}
        </Tile>
    }
  />
  );

export const EventSegment = ( {item} ) => (
  <SegmentTile
    icon={<Icons.Calendar />}  //TicketOutline
    title={item.toStation + " @ " + item.cityCodeTo}
    meta={moment(item.start_date, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
    body={
      <EventBody 
      {...{
        icon: <img src={item.img} alt="IMG" width="64" height="64"/>,
        heading: item.price,
        subHeading: <HostNameLink url={item.link}/>,
        link: item.resy_url,
        addlElement: item.who.split(",").map( (el, idx) => <Badge key={idx} type="info">{el}</Badge>),
        details: `Enter after: ${moment(item.start_date, "YYYY-MM-DD hh:mm").format("LT")}`
      }}
      />
    }
  />
);

export const EventBody = ( {icon, heading, subHeading, link, addlElement, details} ) => (
<Tile
  expandable={!link}
  icon={icon}
  href={link}
  header={
    <Stack align="center" direction="row" justify="between" shrink>
      <Stack direction="column" shrink spacing="none">
        <Stack align="center" direction="row" spacing="XSmall">
          <Heading as="h4" type="title4">{heading}</Heading>
        </Stack>
        <Text>{subHeading}</Text>
      </Stack>
      <Stack spacing="XXXSmall">
      {addlElement}
      </Stack>
    </Stack>}
>
{details}
</Tile> 
);

export const ResBody = ( {item} ) => {
  const searchParams = new URLSearchParams();
  //searchParams.append("city", item.cityFrom); //New York
  searchParams.append("res_date",  item.res_date);
  searchParams.append("party_size", item.party_size);
  searchParams.append("time_start", item.time_start); 
  searchParams.append("time_end", item.time_end);
  searchParams.append("location", item.location);
  searchParams.append("search_radius", 1);
  searchParams.append("cuisine", item.cuisine);
  //party_size=8&res_date=2024-02-22&city=New+York&time_start=19%3A00&time_end=20%3A00&location=&search_radius=1&collection=&cuisine=&seating_type=

  //const url = '/testres' 
  const url =  '/v1/searchres?' + searchParams.toString();

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);
  useEffect(()=>{
     setStatus('Loading');
     fetch(url)
       .then((response) => {
           return response.json()
             .then((json) => {
               if (response.ok) {
                 return Promise.resolve(json)
               }
               return Promise.reject(json)
             })
         })
       .then(setList)
       .then(()=>setStatus('Success'))
       .catch((ex) => {
        console.log(`Exception fetching: ${url}`, ex)
        setStatus(ex.error || ex.message)
       });
   }, []);

  return (
        <Itinerary>
        {status !== 'Success' && <div>{status}</div>}
        {list.description && <h4 style={{padding: '0px 16px'}}>{list.description}</h4>}
        {status === 'Success' && list.results.map( (item, index) => 
          <div key={index}>
          <SegmentTile
            icon={<Icons.Calendar/>} //refund //cocktail
            title={item.name}
            meta={moment(item.good_slots[0].date.start, "YYYY-MM-DD hh:mm").format("h:mma")}
            body={
                <EventBody 
                {...{
                  icon: <img src={item.preview_image_url} alt="IMG" width="64" height="64"/>,
                  heading: `★ ${item.resy_rating.toFixed(1)} (${item.resy_num_ratings}) ⸱ ${'$'.repeat(item.dollar_signs)}`,
                  subHeading: `📍${item.neighborhood}`,
                  link: item.resy_url,
                  details: <div>
                    {item.good_slots.map( (el, idx) => <Badge key={idx}>{moment(el.date.start, "YYYY-MM-DD hh:mm").format("h:mma")}</Badge> )}
                    <div>{item.about}</div>
                    </div>
                }}
                />
            }
          />
          </div>)}
        </Itinerary>
  );
}

export const SearchBody = ( {item, inputsUpdater} ) => {
  const searchParams = new URLSearchParams();
  searchParams.append("origin", item.cityCodeFrom);
  searchParams.append("destination", item.cityCodeTo);
  searchParams.append("start_date", item.start_date)
  if (item.flexible) {
    item.end_date = moment(item.start_date, "YYYY-MM-DD").add(item.flexible, 'days').format("YYYY-MM-DD");
  }
  searchParams.append("end_date", item.end_date)
  searchParams.append("num_passengers", item.adults || 1);
  searchParams.append("selected_cabins", item.selected_cabins || "M");
  searchParams.append("max_stops", item.max_stops || 20);
  searchParams.append("flight_type", "oneway"); //TODO when multistop is added
  //searchParams.append("airline_flight_no", item.airline_flight_no); // &airline_flight_no=UA2191

  const url = '/v1/searchflights?' + searchParams.toString();

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);

  useEffect(()=>{
    if ( !(item.cityCodeFrom && item.cityCodeTo && item.start_date) ) {
      return setStatus()//('Fill out the required details above to get going!')
    }

    if (moment(item.end_date, "YYYY-MM-DD hh:mm") < moment().endOf('day')) {
      return setStatus('Invalid request (end date must not be in past)')
    }

    setStatus('Loading');
    fetch(url)
      .then((response) => {
          return response.json()
            .then((json) => {
              if (response.ok) {
                return Promise.resolve(json)
              }
              return Promise.reject(json)
            })
        })
      .then((json)=> {
        if ( json.data.length == 0 ) setStatus('0 results - maybe try different dates or # of stops?')
        else {
          //setList({data: sortPind(json.data, item.airline_flight_no)})
          setList(json)
          setStatus('Success')
        }
      })
      .catch((ex) => {
       console.log(`Exception fetching: ${url}`, ex)
       setStatus(ex.error || ex.message)
      });
  }, [url]);

  function togglePindArr(planDetails, flightSegment) {
    console.log(planDetails)
    const set = new Set(planDetails?.addlDetails?.pins || []);
    if (set.has(flightSegment)) {
      console.log('del from', set)
      set.delete(flightSegment);
    } else {
      console.log('add from', set)
      set.add(flightSegment);
    }
    return {pins: Array.from(set)};
  }

  function togglePind(planDetails, flightSegment) {

    const route = flightSegment.route[0]
    //const route_id = route.combination_id //id needs date for uniqueness across days
    const route_id = `${flightSegment.route[0].airline}${flightSegment.route[0].flight_no}`
    let current = planDetails.airline_flight_no || ""
    
    console.log(route_id, current, flightSegment.route[0].utc_departure,flightSegment.route[0].combination_id)
  
    if (current.includes(route_id)) {
      current = current.split(',').filter(n => n !== route_id).join(',');
    }
    else {
      current = current.split(',').concat(route_id).join(',');
    }

    console.log(route_id, current)
    return current
  }

  function sortPind(data, pindString) {
    const pindIds = new Set(pindString.split(','));

    data.forEach((item, index) => {
      const routeIds = item.route.map(
        i => i.airline + i.flight_no.toString()
      )
  
      if (routeIds.some(id => pindIds.has(id))) {
        item.pind = true;
      }
      else item.pind = false;
    });

    return [...data.filter(item => item.pind), ...data.filter(item => !item.pind)]
  }
  console.log(item.airline_flight_no)
  return (
        <Itinerary>
        {status !== 'Success' && 
        <>
        {status !== 'Loading' &&
        <>
        <div style={{textAlign: 'center'}}><i>Let's Go</i> is the ultimate 1-way flight finder.</div>
        <ol>
          <li>Choose a destination city/airport ("To")</li>
          <li>Select a date ("When?")</li>
          <li>Add modifiers, like flexible dates or stops</li>
        </ol>
        </>
        }
        <div style={{textAlign: 'center'}}><b>{status}</b></div>
        </>
        }
        {status === 'Success' && sortPind(list.data, item.airline_flight_no || '').map(element => //list.data.map(element => 
          <div key={element.id} > {/*style={{border: '1px solid rgb(0, 122, 255)',}}>*/}
            <FlightSegment item={element}/>
            <div style={{ position: 'relative', float: 'right', top: '-85px', left: '-30px' }}>
              {/*<button onClick={() => {inputsUpdater({...item, ...{airline_flight_no: togglePind(item, element)}}) }} //#009aff
              style={{ margin: '2px 0px', width: '32x', height: '24px', fill: element.pind ? 'rgb(235, 184, 0)': '', transition: 'fill 2000ms linear'}}>
                {element.pind ? <PinButtonFilled/> : <PinButton/> }
                <PindButton pind={element.pind}/>
              </button>*/}
              <button 
                onClick={() => {inputsUpdater({...item, ...{addlDetails: togglePindArr(item, element), airline_flight_no: togglePind(item, element)}}) }}
                //onClick={() => {prompt("Please enter your name", element.route[0].airline + element.route[0].flight_no);}}
                style={{ margin: '2px 0px', width: '32x', height: '32px', paddingInline: '0', fontSize: '17px', fontWeight: '700', color: 'rgb(235, 184, 0)'
                  //backgroundColor: element.pind ? 'rgb(235, 184, 0)': 'transparent', transition: 'background-color 2000ms linear'
                }}
                //type={element.pind ? "submit": ""}
              >
                {element.pind ? '♥': '♡'}{/*📌♡♥*/}
              </button>
              <button 
                onClick={() => {window.location.href = element.deep_link;}}
                style={{ margin: '2px 0px', width: '64px', height: '32px', fontSize: '14px' }}
                type="submit"
              >
                ${element.fare.adults}
              </button>
            </div>
          </div>)}
        </Itinerary>
  );
}

export const SearchSegment = ( {item} ) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
  <SegmentTile
    onClick={() => setShowModal(true)}
    icon={<Icons.Airplane/>}
    title={item.toStation}
    meta={moment(item.start_date, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
    countryToCode={item.countryToCode}
    body={showModal && (
      <Modal
        onClose={(e) => {
          setShowModal(false);
          e.stopPropagation();
        }}
      >
        <ModalHeader
          title={item.cityCodeFrom + ' → ' + item.cityCodeTo}
          description={moment(item.start_date, "YYYY-MM-DD hh:mm").format("ddd, MMM D")}
        />
        <ModalSection>
        <SearchBody item={item}/>
        </ModalSection>
        
      </Modal>
    )       
    }
  />

  );
  }

export const ExpenseSegment = ( {item} ) => {
  const expensesNetted = item.ex_owes != 0 ? `(${item.ex_owed}) - (${item.ex_owes})` : `(${item.ex_owed})`
  return (
  <SegmentTile
    icon={<Icons.CreditCard />} //refund //cocktail
    title={item.owed + " to request " + item.owes + ":"}//{item.owes + " -> " + item.owed}
    meta={"$" + item.splitNetted}
    body={
        <Tile
          title={"Net owed for:"}
          description={expensesNetted}
          icon={<img src={item.img} alt="IMG" width="64" height="64"/>}
          href={`venmo://paycharge?txn=charge&recipients=${
              item.owes_venmo}&amount=${
                  item.splitNetted}&note=${
                      expensesNetted}`}
        >
        </Tile>
    }
  />
  );
}

export const segmentTypes = {
  'flight': FlightSegment,
  'flight_segment': FlightsSegment,
  'accommodation': AccommodationSegment,
  'event': EventSegment,
  'expense': ExpenseSegment,
  'search': SearchSegment
}