import { useEffect } from "react";
import { FormInput } from './HookFormInputs';
//import { FormInput } from './components/FormInput';
import InputField from "@kiwicom/orbit-components/lib/InputField";
import InputGroup from "@kiwicom/orbit-components/lib/InputGroup";
import moment from 'moment'

const areaOptions = [
    'Williamsburg',
    'Greenpoint',
    'West Village',                       
    'Tribeca',
    'Midtown',
    'Uptown'
]

const AddlInputs = ( {register, setValue, location = ''} ) => {

  register("link");
  register("name");

  useEffect(() => {
    if (location.length > 0) {
        setValue("link", `letsgo.to/${location}`);
        setValue("name", location);
    }
  }, [location]);

  return (
    <>
    </>
  );
};

export const ResAdder = ( {register, watch, setValue} ) => {
  const locationWatcher = watch('location')

  return (
    <>
        <FormInput 
              type="date"
              id="res_date"
              defaultValue={moment().format('YYYY-MM-DD')}
              
              label="Reservation Date"
              register={register}
              name="res_date"
        />
        <InputGroup flex={["2 2 6em", "2 2 6em"]} label="Times Between">
        <FormInput 
              type="time"
              id="time_start"
              //step="900"
              defaultValue="19:00:00"
              
              label="Earliest Time"
              register={register}
              name="time_start"
        />
        <FormInput 
              type="time"
              id="time_end"
              //step="900"
              defaultValue="21:00:00"
              
              label="Latest Time"
              register={register}
              name="time_end"
        />
        </InputGroup>
        <FormInput 
              type="number"
              id="party_size"
              min={1}
              defaultValue="2"
              
              label="Party Size"
              register={register}
              name="party_size"
        />
        <FormInput 
              type="text"
              id="location"

              label="Select An Area"
              register={register}
              name="location"
              list="areaOptions"
        />
        <datalist id="areaOptions">
        {
          areaOptions && areaOptions.map( (item, index) =>
          <option value={item} key={index}></option>
          )
        }
        </datalist>
        <InputField 
          label="Vibe"
          placeholder="vibe or cuisine"
          name="cuisine"
          {...register('cuisine')}
        />
        <AddlInputs register={register} setValue={setValue} location={locationWatcher}/>

    </>
  );
};