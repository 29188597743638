import React from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from './PlanCard';

export const FlightPlan = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const planDetails = Object.fromEntries(searchParams.entries())
  const inputPlan = {planType: "input", planDetails}

  const setParams = (details) => {
    const { addlDetails, ...params } = details;
    const pind = addlDetails.pins.map((flightSegment) => {
      return `${flightSegment.route[0].airline}${flightSegment.route[0].flight_no}`
    })

    console.log(addlDetails)
    console.log(pind.join(","))
    setSearchParams(params)
    /*cityCodeFrom: "NYC"
    cityCodeTo: "LAX"
    end_date: "2024-10-16"
    flexible: "0"
    max_stops: "0"
    num_passengers: "1"
    selected_cabins: "M"
    start_date: "2024-10-16"
    codes?*/
  }

  const cardStyle = {
    background: 'RGB(242,242,247)', //'RGB(178,179,178)',//'RGB(179,201,253)',
    height: '100svh',
  }

   return (
      <div>
         {<Card trip={inputPlan} cardStyle={cardStyle} planUpdater={setParams}/>}
       </div>
     );
}