import react, { useEffect, useState } from "react";
import { PlusButton } from './components/PlusButton';
import { ListButton } from './components/ListButton';
import {SegmentAdder} from './SegmentAdder';
import {PlansModal} from './PlansModal';
import {PlanCard} from './PlanCard';
import {BlurBar} from './components/BlurBar';
import Card from './cardStack/Card';
import CardStack from './cardStack/CardStack';

export const HomePage = ({ stack = false }) => {
  const [showPlusModal, setShowPlusModal] = useState(false);
  const [status, setStatus] = useState('');
  const [plans, setPlans] = useState([]);
  const [selValue, setSelValue] = useState('all');
  const [selOptions, setSelOptions] = useState([]);
  const [selectedCardIdx, setSelectedCardIdx] = useState(-1);

  const setData = (data) => {
    if (plans.length==1) setSelectedCardIdx(0) //need X button when 1 plan, doesnt work on first load
    setPlans(data)

    const options = !stack
         ? data.map(p => p.planName)
         : ['all', { 'plan types': Array.from(new Set(data.map(p => p.planType))) }]
    setSelOptions(options)
    !stack && setSelValue(options[0])
  }

  const addPlan = () => {
    const planId = plans.length //+1
    const newPlan = {
      planType: "input",
      planId: planId,
      planName: `Plan ${planId}`,
      planDetails: {},
    }
    //setData((plans) => [...plans, newPlan])
    setPlans(plans => [...plans, newPlan])
    setSelectedCardIdx(0)
  }

  const updatePlan = (id) => (planDetails) => {
    //planDetails = {...planDetails, cityCodeTo: planDetails.cityCodeTo.slice(0,3)}
    //planDetails.cityCodeTo = planDetails.cityCodeTo.slice(0,3)
    const updInputPlan = {...plans[id], planDetails} //planDetails must keep same name/key not be pd
    const updPlans = plans
    updPlans[id] = updInputPlan
    setData(updPlans)
    localStorage.setItem('plans',JSON.stringify(updPlans));
    // https://stackoverflow.com/questions/2989284/what-is-the-max-size-of-localstorage-values
    //return () => onClick(id);
  };

  const deletePlan = (idx) => {
    const planId = plans.toReversed()[idx].planId
    const updPlans = plans.filter(plan => plan.planId !== planId);
    setData(updPlans)
    console.log(idx, updPlans)
    localStorage.setItem('plans',JSON.stringify(updPlans));
    setSelectedCardIdx(-1)
    //return () => onClick(id);
  };

  const plansURL = '/testplans' //'plans' //

  useEffect(()=>{
     setStatus('loading');
     const plansStr = localStorage.getItem('plans')
     if (plansStr) {
      console.log('yep', JSON.parse(plansStr))
      setData(JSON.parse(plansStr))
     }
     else {
      addPlan()
     }
     setStatus('success')
     /*fetch(plansURL)
       .then(response => response.json())
       .then(resjson => resjson.plans.length > 0 && setData(resjson.plans.toReversed()))
       .then(()=>setStatus('success'))
       .catch(()=>setStatus('error'));*/
     }, []);

  const addTrip = (payload) => {
    fetch(plansURL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(resjson => setData(resjson.plans.toReversed()))
    .then(()=>setStatus('success'))
    .then(()=>setShowPlusModal(false)) //block/show loading until plan is added
    .catch(()=>setStatus('error'));
  }

  const toolbarStyle = {
    overscrollBehavior: 'contain', //https://dev.to/mpuckett/the-holy-grail-web-app-shell-with-header-and-footer-for-iphone-549j
    //overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff', 
    padding: '2.25vh 0vw',//'4.5vw 0vw', //slightly less padding than native passbook
    fontSize: '4.5vh', 
    maxHeight: '4.5vh',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif',
    //https://stackoverflow.com/questions/32660748/how-to-use-apples-new-san-francisco-font-on-a-webpage
  }

  const plansFiltered = plans.filter((p) => selValue == 'all' || selValue == p.planType || selValue == p.planName)

  // bodyHeight = totalHeight - toolbarHeight - blurbarHeight (if it's visible)
  const totalHeightInPixels = window.innerHeight;
  const toolbarHeight = totalHeightInPixels * (.045 + .045)
  const blurbarHeight = totalHeightInPixels * .045 * (window.navigator.standalone | 0)
  const bodyHeightInPixels = Math.floor(totalHeightInPixels - (toolbarHeight + blurbarHeight));

  return (
    <div style={{padding: '0vw 4.5vw'}}>
       <div style={toolbarStyle}>
        {status !== 'error'
                ? <b onClick={() => setShowPlusModal(true)}>Plans</b>
                : <b>No Plans Found</b>
        }
        <div style={{display: 'flex'}}>
          {/* <ListButton diameter='4.5vh' selOptions={selOptions} changeHandler={(val) => setSelValue(val)}/> */}
          {selectedCardIdx >= 0 && 
          <PlusButton diameter='4.5vh' status={'error'} onClick={() => deletePlan(selectedCardIdx)}/>
          }
          <div style={{width: '2.25vh'}}></div>
          <PlusButton diameter='4.5vh' status={status} onClick={addPlan}//{() => setShowPlusModal(true)}
            />
        {showPlusModal && <PlansModal plans={plans} setShowPlusModal={setShowPlusModal}/>
        }
       </div>
       </div>

       {
        status === 'success' && plansFiltered.length === 0 && (
           <div style={toolbarStyle}>
             Hit the plus button to add a plan!
           </div>
        )
       }
       {
        status === 'success' && plans.length > 0 && (
           (!stack || plansFiltered.length === 1)
             ? <PlanCard trip={plansFiltered[0]} height={bodyHeightInPixels} planUpdater={updatePlan}/>
             : <CardStack background='transparent' height={bodyHeightInPixels} footerHeight={blurbarHeight} headerHeight={60}
             selectedIdx={selectedCardIdx} selectedUpdater={setSelectedCardIdx}
             >
                 {plansFiltered.toReversed() //[inputPlan].concat(plansFiltered)
                 .map((plan) => (
                   <Card key={plan.planId}>
                     <PlanCard trip={plan} height={bodyHeightInPixels} //planUpdater={updatePlan}
                     {...(selectedCardIdx >= 0 ? {planUpdater: updatePlan} : {})}/>
                   </Card>
                 ))}
               </CardStack>
        )
       }

       {window.navigator.standalone && <BlurBar/>}
     </div>
     );
}