import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import { SheetTrip } from './SheetTrip';
import { SheetExpenses } from './SheetExpenses';
import { FlightPlan } from './FlightPlan';
import { HomePage } from './HomePage';
import { PWA } from './PWA';
import { LandingPage } from './LandingPage';
import * as Test from './_local/Plan';
import reportWebVitals from './reportWebVitals'

//commenting out as part of react 16.14 ->18 ugprade
//import registerServiceWorker from './registerServiceWorker';

//ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/stack" element={<HomePage stack={true} />} />
          <Route exact path="/pwa" element={<PWA stack={true} />} />
          <Route path="/s/:sheetId" element={<SheetTrip />} />
          <Route path="/e/:sheetId" element={<SheetExpenses />} />
          <Route path="/explore" element={<FlightPlan />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/app" element={<App />} />
          <Route path="/test" element={<Test.Plan />} />
          {/*<Route path="invoices" element={<Invoices />} />*/}
        </Routes>
      </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();