
import Modal, {ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import Button from "@kiwicom/orbit-components/lib/Button";
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import { FlightSegment } from './Segments';


export const PlansModal = ( {plans, setShowPlusModal} ) => {
  console.log(plans)

  return (
    <Modal
      onClose={(e) => {
        setShowPlusModal(false);
        e.stopPropagation();
      }}
    >
      <ModalHeader
        title='Plans'
        //description='Likes from across all your plans live here :)'
      />
      <ModalSection>

      <Itinerary>
      {plans.toReversed()
      .map((plan) => (
        <>
            {plan.planDetails?.addlDetails?.pins?.map((pind) => (
                <FlightSegment item={pind}/>
            ))
              //<FlightSegment item={plan.planDetails.addlDetails}/>
              //{addlDetails: {pins: [element]}
            }
        </>
      ))}
      </Itinerary>

      </ModalSection>
      <ModalFooter>
        <Button 
          fullWidth={true} 
          submit={true}
        >
        Share
        </Button>
      </ModalFooter>
    </Modal>
  );
};