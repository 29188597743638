export const AirportsDataList = ( {id, search_term} ) => {
    console.log(new Date().toLocaleTimeString(),'render AirportsDataList')
    const optionKeys = search_term 
               ? Object.keys(AirportCodes).filter(key => 
                 key.toLowerCase().includes(search_term.toLowerCase())
               ) 
               : ["NYC", "LAX", "MIA"] // defaults (no search_term)
    console.log(new Date().toLocaleTimeString(), optionKeys.length)
    return (
        <datalist id={id}>
        {
          optionKeys
          .slice(0,20) //60
          .map( (item, index) =>
              <option value={`${item} (${AirportCodes[item]})`} key={index}>{AirportCodes[item]}</option>
          )
        }
        </datalist>
    )  
}

//manually added: "NYC": "New York City",
export const AirportCodes = {
    "AAA": "Anaa",
    "AAE": "Rabah Bitat",
    "AAL": "Aalborg",
    "AAM": "Malamala Airport",
    "AAN": "Al Ain International",
    "AAP": "APT Pranoto Airport",
    "AAR": "Aarhus",
    "AAT": "Altay",
    "AAX": "Araxá",
    "AAZ": "Quezaltenango Airport",
    "ABB": "Asaba International",
    "ABE": "Lehigh Valley International",
    "ABI": "Abilene Regional",
    "ABJ": "Port Bouet",
    "ABK": "Kabri Dar",
    "ABM": "Northern Peninsula",
    "ABQ": "Albuquerque International Sunport",
    "ABR": "Aberdeen Regional",
    "ABS": "Abu Simbel",
    "ABT": "Al-Baha Domestic",
    "ABU": "Haliwen",
    "ABV": "Nnamdi Azikiwe International",
    "ABX": "Albury",
    "ABY": "Southwest Georgia Regional",
    "ABZ": "Aberdeen",
    "ACA": "Acapulco International",
    "ACC": "Kotoka International",
    "ACD": "Alcides Fernández",
    "ACE": "Lanzarote",
    "ACH": "St. Gallen–Altenrhein",
    "ACI": "Alderney",
    "ACK": "Nantucket Memorial",
    "ACO": "Cobano Airport",
    "ACT": "Waco Regional",
    "ACV": "Arcata–Eureka",
    "ACX": "Xingyi Wanfenglin",
    "ACY": "Atlantic City International",
    "ADA": "Adana Şakirpaşa",
    "ADB": "Adnan Menderes",
    "ADD": "Addis Ababa Bole International",
    "ADE": "Aden International Airport",
    "ADF": "Adıyaman",
    "ADK": "Adak",
    "ADL": "Adelaide",
    "ADQ": "Kodiak",
    "ADZ": "Gustavo Rojas Pinilla International",
    "AEB": "Baise Bama",
    "AEP": "Aeroparque Jorge Newbery",
    "AER": "Sochi International",
    "AES": "Ålesund Airport, Vigra",
    "AEX": "Alexandria International",
    "AEY": "Akureyri",
    "AFA": "San Rafael Airport",
    "AFL": "Alta Floresta",
    "AGA": "Agadir–Al Massira",
    "AGH": "Ängelholm–Helsingborg",
    "AGN": "Angoon Seaplane Base",
    "AGP": "Málaga",
    "AGR": "Agra",
    "AGS": "Augusta Regional",
    "AGT": "Guaraní International",
    "AGU": "Aguascalientes International",
    "AHB": "Abha Regional",
    "AHE": "Ahe",
    "AHJ": "Aba/Hongyuan",
    "AHO": "Alghero-Fertilia",
    "AHU": "Cherif Al Idrissi",
    "AHX": "Anholt Airport",
    "AIA": "Alliance Municipal",
    "AIR": "Aripuanã Airport",
    "AIT": "Aitutaki",
    "AIU": "Enua",
    "AJA": "Ajaccio Napoleon Bonaparte",
    "AJF": "Al-Jawf Domestic",
    "AJI": "Ağrı",
    "AJL": "Lengpui",
    "AJN": "Ouani",
    "AJR": "Arvidsjaur",
    "AJU": "Santa Maria",
    "AKA": "Ankang Airport",
    "AKJ": "Asahikawa",
    "AKL": "Auckland",
    "AKN": "King Salmon",
    "AKR": "Akure",
    "AKS": "Auki Gwaunaru u",
    "AKU": "Aksu",
    "AKV": "Akulivik",
    "AKX": "Aktobe",
    "AKY": "Sittwe",
    "ALA": "Almaty International",
    "ALB": "Albany International",
    "ALC": "Alicante–Elche",
    "ALF": "Alta",
    "ALG": "Houari Boumediene",
    "ALH": "Albany",
    "ALO": "Waterloo Regional",
    "ALS": "San Luis Valley Regional",
    "ALW": "Walla Walla Regional",
    "AMA": "Rick Husband Amarillo International",
    "AMD": "Sardar Vallabhbhai Patel International",
    "AMH": "Arba Minch",
    "AMM": "Queen Alia International",
    "AMQ": "Pattimura",
    "AMS": "Amsterdam Airport Schiphol",
    "ANA": "Maasai Mara Angama",
    "ANC": "Ted Stevens Anchorage International",
    "ANF": "Andrés Sabella Gálvez International",
    "ANR": "Antwerp International",
    "ANU": "V. C. Bird International",
    "ANX": "Andøya",
    "AOE": "Anadolu",
    "AOG": "Anshan Teng ao",
    "AOI": "Marche Airport",
    "AOJ": "Aomori",
    "AOK": "Karpathos Island National",
    "AOO": "Altoona–Blair County",
    "AOR": "Sultan Abdul Halim",
    "APK": "Apataki",
    "APL": "Nampula",
    "APN": "Alpena County Regional",
    "APO": "Antonio Roldán Betancourt",
    "APW": "Faleolo International",
    "AQA": "Araraquara Airport",
    "AQG": "Anqing Tianzhushan",
    "AQI": "Al Qaisumah/Hafr Al Batin",
    "AQJ": "King Hussein International",
    "AQP": "Rodríguez Ballón International",
    "ARD": "Alor Island",
    "ARI": "Chacalluta International",
    "ARK": "Arusha",
    "ARM": "Armidale",
    "ARN": "Stockholm Arlanda",
    "ART": "Watertown International",
    "ARU": "Araçatuba",
    "ARW": "Arad International",
    "ASB": "Ashgabat International",
    "ASE": "Aspen–Pitkin County",
    "ASF": "Narimanovo",
    "ASJ": "Amami",
    "ASM": "Asmara International",
    "ASO": "Asosa",
    "ASP": "Alice Springs",
    "ASR": "Erkilet International",
    "ASU": "Silvio Pettirossi International",
    "ASV": "Amboseli",
    "ASW": "Aswan International",
    "ATA": "Comandante FAP Germán Arias Graziani",
    "ATD": "Uru Harbour",
    "ATH": "Athens International",
    "ATL": "Hartsfield–Jackson Atlanta International",
    "ATM": "Altamira",
    "ATQ": "Sri Guru Ram Dass Jee International",
    "ATR": "Atar International Airport",
    "ATW": "Appleton International",
    "ATY": "Watertown Regional",
    "ATZ": "Assiut",
    "AUA": "Queen Beatrix International",
    "AUC": "Santiago Pérez Quiroz",
    "AUG": "Augusta State",
    "AUH": "Zayed International Airport",
    "AUQ": "Atuona",
    "AUR": "Aurillac – Tronquières",
    "AUS": "Austin–Bergstrom International",
    "AUU": "Aurukun",
    "AVL": "Asheville Regional",
    "AVP": "Wilkes-Barre/Scranton International",
    "AVV": "Avalon",
    "AWA": "Awasa",
    "AXA": "Clayton J. Lloyd International",
    "AXD": "Alexandroupolis International",
    "AXJ": "Amakusa Airfield",
    "AXM": "El Edén International",
    "AXP": "Spring Point",
    "AXR": "Arutua",
    "AXT": "Akita",
    "AXU": "Axum",
    "AYP": "Coronel FAP Alfredo Mendívil Duarte",
    "AYQ": "Ayers Rock",
    "AYT": "Antalya",
    "AZA": "Phoenix–Mesa Gateway",
    "AZO": "Kalamazoo/Battle Creek International",
    "AZR": "Touat-Cheikh Sidi Mohamed Belkebir",
    "AZS": "Samaná El Catey International",
    "BAH": "Bahrain International",
    "BAL": "Batman",
    "BAQ": "Ernesto Cortissoz International",
    "BAR": "Qionghai Bo'ao",
    "BAS": "Balalae",
    "BAV": "Baotou",
    "BAY": "Baia Mare Airport",
    "BBA": "Balmaceda",
    "BBI": "Biju Patnaik International",
    "BBK": "Kasane",
    "BBN": "Bario",
    "BBU": "Baneasa International Airport",
    "BCD": "Bacolod–Silay",
    "BCI": "Barcaldine",
    "BCM": "George Enescu International",
    "BCN": "Barcelona–El Prat",
    "BCO": "Baco",
    "BDA": "L.F. Wade International",
    "BDB": "Bundaberg",
    "BDD": "Badu Island Airport",
    "BDJ": "Syamsudin Noor International",
    "BDL": "Bradley International",
    "BDP": "Bhadrapur",
    "BDQ": "Vadodara",
    "BDS": "Brindisi",
    "BDU": "Bardufoss",
    "BEB": "Benbecula",
    "BEG": "Belgrade Nikola Tesla",
    "BEJ": "Kalimarau",
    "BEK": "Bareilly Airport",
    "BEL": "Val de Cans International",
    "BEM": "Beni Mellal",
    "BEN": "Benina International",
    "BER": "Berlin Brandenburg",
    "BES": "Brest Bretagne",
    "BET": "Bethel",
    "BEU": "Bedourie",
    "BEW": "Beira",
    "BEY": "Beirut–Rafic Hariri International",
    "BFD": "Bradford Regional",
    "BFF": "Western Nebraska Regional",
    "BFJ": "Bijie Feixiong",
    "BFL": "Meadows Field",
    "BFM": "Mobile Downtown Airport",
    "BFN": "Bloemfontein",
    "BFS": "Belfast International",
    "BFV": "Buriram",
    "BFX": "Bafoussam",
    "BGA": "Palonegro International",
    "BGC": "Braganca",
    "BGF": "Bangui M Poko International",
    "BGG": "Bingöl",
    "BGI": "Grantley Adams International",
    "BGM": "Greater Binghamton",
    "BGO": "Bergen Airport, Flesland",
    "BGR": "Bangor International",
    "BGW": "Baghdad International",
    "BGY": "Milan Bergamo International Airport",
    "BHB": "Hancock County-Bar Harbor",
    "BHD": "George Best Belfast City",
    "BHE": "Woodbourne",
    "BHH": "Bisha Domestic",
    "BHI": "Comandante Espora",
    "BHJ": "Bhuj",
    "BHK": "Bukhara International",
    "BHM": "Birmingham-Shuttlesworth International",
    "BHO": "Raja Bhoj",
    "BHQ": "Broken Hill",
    "BHR": "Bharatpur",
    "BHU": "Bhavnagar",
    "BHX": "Birmingham",
    "BHY": "Beihai Fucheng",
    "BIA": "Bastia – Poretta",
    "BIH": "Eastern Sierra Regional Airport",
    "BIK": "Frans Kaisiepo International",
    "BIL": "Billings Logan International",
    "BIM": "South Bimini",
    "BIO": "Bilbao",
    "BIQ": "Biarritz Pays Basque",
    "BIR": "Biratnagar",
    "BIS": "Bismarck Municipal",
    "BJA": "Abane Ramdane",
    "BJF": "Båtsfjord",
    "BJI": "Bemidji Regional",
    "BJL": "Banjul International",
    "BJM": "Bujumbura International",
    "BJR": "Bahir Dar",
    "BJV": "Milas–Bodrum",
    "BJW": "Turelelo Soa",
    "BJX": "Del Bajío International",
    "BJZ": "Badajoz",
    "BKI": "Kota Kinabalu International",
    "BKK": "Suvarnabhumi",
    "BKM": "Ba kelalan",
    "BKO": "Bamako–Sénou International",
    "BKQ": "Blackall",
    "BKS": "Fatmawati Soekarno",
    "BKW": "Raleigh County Memorial",
    "BKZ": "Bukoba",
    "BLA": "General José Antonio Anzoátegui International",
    "BLB": "Panamá Pacífico International Airport",
    "BLI": "Bellingham International",
    "BLJ": "Mostépha Ben Boulaid",
    "BLL": "Billund",
    "BLQ": "Bologna Guglielmo Marconi",
    "BLR": "Bengaluru International",
    "BLV": "MidAmerica St. Louis",
    "BLZ": "Chileka International",
    "BMA": "Stockholm Bromma",
    "BME": "Broome International",
    "BMI": "Central Illinois Regional",
    "BMO": "Bhamo",
    "BMU": "Sultan Muhammad Salahudin",
    "BMV": "Buon Ma Thuot",
    "BMW": "Bordj Mokhtar",
    "BNA": "Nashville International",
    "BND": "Bandar Abbas International",
    "BNE": "Brisbane",
    "BNI": "Benin",
    "BNK": "Ballina Byron Gateway",
    "BNN": "Brønnøysund Airport, Brønnøy",
    "BNX": "Banja Luka International",
    "BNY": "Bellona/Anua",
    "BOB": "Bora Bora",
    "BOC": "Bocas del Toro Isla Colón International",
    "BOD": "Bordeaux–Mérignac",
    "BOG": "El Dorado International",
    "BOH": "Bournemouth",
    "BOI": "Boise",
    "BOJ": "Burgas",
    "BOM": "Chhatrapati Shivaji International",
    "BON": "Flamingo International",
    "BOO": "Bodø",
    "BOS": "Logan International",
    "BOY": "Bobo Dioulasso",
    "BPE": "Qinhuangdao Beidaihe",
    "BPG": "Barra do Garças",
    "BPL": "Bole Alashankou",
    "BPN": "Sultan Aji Muhammad Sulaiman",
    "BPS": "Porto Seguro",
    "BPT": "Jack Brooks Regional",
    "BPX": "Qamdo Bamda",
    "BQB": "Busselton Regional",
    "BQK": "Brunswick Golden Isles",
    "BQL": "Boulia",
    "BQN": "Aguadilla Rafael Hernandez",
    "BRA": "Barreiras",
    "BRB": "Barreirinhas Airport",
    "BRC": "San Carlos de Bariloche",
    "BRD": "Brainerd Lakes Regional",
    "BRE": "Bremen",
    "BRI": "Bari Karol Wojtyła",
    "BRL": "Southeast Iowa Regional",
    "BRM": "Jacinto Lara International",
    "BRN": "Bern",
    "BRO": "Brownsville/South Padre Island International",
    "BRQ": "Brno–Tuřany",
    "BRR": "Barra",
    "BRS": "Bristol",
    "BRU": "Brussels",
    "BRW": "Wiley Post–Will Rogers Memorial",
    "BSA": "Bosaso",
    "BSB": "Brasília International",
    "BSC": "Jose Celestino Mutis",
    "BSD": "Baoshan Yunrui",
    "BSG": "Bata",
    "BSK": "Biskra",
    "BSL": "EuroAirport Basel Mulhouse Freiburg",
    "BSO": "Basco",
    "BSR": "Basra International",
    "BTC": "Batticaloa",
    "BTH": "Hang Nadim",
    "BTJ": "Sultan Iskandar Muda International",
    "BTM": "Bert Mooney",
    "BTR": "Baton Rouge Metropolitan",
    "BTS": "Bratislava Airport",
    "BTU": "Bintulu",
    "BTV": "Burlington International",
    "BTW": "Batu Licin",
    "BUA": "Buka",
    "BUC": "Burketown",
    "BUD": "Budapest Ferenc Liszt International",
    "BUF": "Buffalo Niagara International",
    "BUN": "Gerardo Tobar López",
    "BUQ": "Joshua Mqabuko Nkomo International",
    "BUR": "Hollywood Burbank",
    "BUS": "Batumi International",
    "BUW": "Baubau",
    "BVA": "Beauvais–Tillé",
    "BVB": "Boa Vista International",
    "BVC": "Aristides Pereira International",
    "BVE": "Brive–Souillac Airport",
    "BVG": "Berlevåg",
    "BVH": "Vilhena",
    "BVI": "Birdsville",
    "BVS": "Breves Airport",
    "BWA": "Gautam Buddha",
    "BWI": "Baltimore–Washington International",
    "BWK": "Brač",
    "BWN": "Brunei International",
    "BWT": "Burnie",
    "BWX": "Blimbingsari",
    "BXG": "Bendigo Airport",
    "BXU": "Bancasi",
    "BYK": "Bouaké",
    "BYO": "Bonito",
    "BYR": "Laeso Airport",
    "BZE": "Philip S. W. Goldson International",
    "BZG": "Bydgoszcz Ignacy Jan Paderewski",
    "BZL": "Barisal",
    "BZN": "Bozeman Yellowstone International",
    "BZO": "Bolzano Airport",
    "BZR": "Béziers Cap d Agde",
    "BZV": "Maya-Maya",
    "BZX": "Bazhong Enyang Airport",
    "CAB": "Cabinda",
    "CAC": "Cascavel",
    "CAE": "Columbia Metropolitan",
    "CAG": "Cagliari Elmas",
    "CAH": "Cà Mau",
    "CAI": "Cairo International",
    "CAK": "Akron–Canton",
    "CAL": "Campbeltown",
    "CAN": "Guangzhou Baiyun International",
    "CAP": "Cap-Haïtien International",
    "CAQ": "Juan H. White",
    "CAT": "Cascais Tires",
    "CAU": "Caruaru Airport",
    "CAW": "Bartolomeu Lysandro",
    "CAY": "Cayenne – Félix Eboué",
    "CAZ": "Cobar",
    "CBB": "Jorge Wilstermann International",
    "CBH": "Boudghene Ben Ali Lotfi",
    "CBO": "Awang",
    "CBQ": "Margaret Ekpo International",
    "CBR": "Canberra",
    "CBT": "Catumbela",
    "CCF": "Carcassonne",
    "CCJ": "Calicut International",
    "CCK": "Cocos (Keeling) Islands",
    "CCP": "Carriel Sur International",
    "CCS": "Simón Bolívar International",
    "CCU": "Netaji Subhas Chandra Bose International",
    "CDB": "Cold Bay",
    "CDC": "Cedar City Regional",
    "CDE": "Chengde Puning",
    "CDG": "Charles de Gaulle Airport",
    "CDP": "Cuddapah",
    "CDR": "Chadron Municipal",
    "CDT": "Castellón–Costa Azahar",
    "CDV": "Merle K. (Mudhole) Smith",
    "CEB": "Mactan–Cebu International",
    "CEC": "Del Norte County",
    "CED": "Ceduna",
    "CEI": "Chiang Rai International",
    "CEN": "Ciudad Obregón International",
    "CEZ": "Cortez Municipal",
    "CFB": "Cabo Frio International",
    "CFE": "Clermont-Ferrand Auvergne",
    "CFK": "Chlef International",
    "CFN": "Donegal",
    "CFR": "Caen – Carpiquet",
    "CFS": "Coffs Harbour",
    "CFU": "Corfu International",
    "CGB": "Marechal Rondon International",
    "CGD": "Changde Taohuayuan",
    "CGH": "São Paulo–Congonhas",
    "CGI": "Cape Girardeau Regional",
    "CGK": "Soekarno–Hatta International",
    "CGM": "Camiguin",
    "CGN": "Cologne Bonn Airport",
    "CGO": "Zhengzhou Xinzheng International",
    "CGP": "Shah Amanat International",
    "CGQ": "Changchun Longjia International",
    "CGR": "Campo Grande International",
    "CGY": "Laguindingan Airport",
    "CHA": "Chattanooga Metropolitan",
    "CHC": "Christchurch International",
    "CHG": "Chaoyang",
    "CHH": "Chachapoyas Airport",
    "CHO": "Charlottesville–Albemarle",
    "CHQ": "Chania International",
    "CHS": "Charleston International",
    "CHT": "Chatham Islands-Tuuta Airport",
    "CHX": "Changuinola Capitán Manuel Niño International",
    "CHY": "Choiseul Bay",
    "CIA": "Ciampino–G. B. Pastine International",
    "CID": "The Eastern Iowa",
    "CIF": "Chifeng Yulong",
    "CIH": "Changzhi Wangcun",
    "CIJ": "Captain Aníbal Arab",
    "CIT": "Shymkent International",
    "CIU": "Chippewa County International",
    "CIX": "FAP Captain José Abelardo Quiñones Gonzáles International",
    "CIY": "Comiso",
    "CIZ": "Municipal Airport Danilson Aires Cirino da Silva / Coari Airport",
    "CJA": "Mayor General FAP Armando Revoredo Iglesias",
    "CJB": "Coimbatore International",
    "CJC": "El Loa",
    "CJJ": "Cheongju International",
    "CJL": "Chitral",
    "CJM": "Chumphon",
    "CJS": "Ciudad Juárez International",
    "CJU": "Jeju International",
    "CJZ": "Cajazeiras Airport",
    "CKB": "North Central West Virginia",
    "CKG": "Chongqing Jiangbei International",
    "CKS": "Carajás",
    "CKY": "Conakry International",
    "CKZ": "Çanakkale",
    "CLD": "McClellan–Palomar",
    "CLE": "Cleveland Hopkins International",
    "CLJ": "Cluj International",
    "CLL": "Easterwood",
    "CLO": "Alfonso Bonilla Aragón International",
    "CLQ": "Colima",
    "CLT": "Charlotte Douglas International",
    "CLV": "Caldas Novas",
    "CLY": "Calvi – Sainte-Catherine",
    "CMA": "Cunnamulla",
    "CMB": "Bandaranaike International",
    "CME": "Ciudad del Carmen International",
    "CMF": "Chambéry",
    "CMG": "Corumbá International",
    "CMH": "John Glenn Columbus International",
    "CMI": "University of Illinois Willard",
    "CMN": "Mohammed V International",
    "CMX": "Houghton County Memorial",
    "CNC": "Coconut Island Airport",
    "CND": "Mihail Kogălniceanu International",
    "CNF": "Tancredo Neves International",
    "CNJ": "Cloncurry",
    "CNM": "Cavern City Air Terminal",
    "CNN": "Kannur International Airport",
    "CNQ": "Doctor Fernando Piragine Niveyro International",
    "CNS": "Cairns",
    "CNX": "Chiang Mai International",
    "CNY": "Canyonlands Field",
    "COD": "Yellowstone Regional",
    "COG": "Condoto Mandinga",
    "COK": "Cochin International",
    "COO": "Cadjehoun",
    "COR": "Ingeniero Aeronáutico Ambrosio L.V. Taravella International",
    "COS": "Colorado Springs",
    "COU": "Columbia Regional",
    "COV": "Cukurova International Airport",
    "CPC": "Aviador Carlos Campos",
    "CPD": "Coober Pedy",
    "CPE": "Campeche International",
    "CPH": "Airport Copenhagen",
    "CPO": "Chamonate",
    "CPR": "Casper–Natrona County International",
    "CPT": "Cape Town International",
    "CPV": "Campina Grande",
    "CPX": "Benjamín Rivera Noriega",
    "CRA": "Craiova International",
    "CRD": "General Enrique Mosconi International",
    "CRI": "Colonel Hill",
    "CRK": "Clark International",
    "CRL": "Brussels South Charleroi",
    "CRM": "Catarman National",
    "CRP": "Corpus Christi International",
    "CRV": "Crotone Airport",
    "CRW": "Yeager",
    "CRZ": "Turkmenabat",
    "CSG": "Columbus Metropolitan",
    "CSK": "Cap Skirring",
    "CSX": "Changsha Huanghua International",
    "CTA": "Catania–Fontanarossa",
    "CTC": "Coronel Felipe Varela International",
    "CTD": "Chitre Alonso Valderrama",
    "CTG": "Rafael Núñez International",
    "CTL": "Charleville",
    "CTM": "Chetumal International",
    "CTS": "New Chitose",
    "CTU": "Chengdu Shuangliu International",
    "CUC": "Camilo Daza International",
    "CUE": "Mariscal Lamar International",
    "CUF": "Cuneo International",
    "CUK": "Caye Caulker",
    "CUL": "Bachigualato Federal International",
    "CUN": "Cancún International",
    "CUR": "Curaçao International",
    "CUU": "Chihuahua International",
    "CUZ": "Alejandro Velasco Astete International",
    "CVG": "Cincinnati/Northern Kentucky International",
    "CVN": "Clovis Municipal",
    "CVQ": "Carnarvon",
    "CVU": "Corvo",
    "CWA": "Central Wisconsin",
    "CWB": "Afonso Pena International",
    "CWJ": "Cangyuan Washan",
    "CWL": "Cardiff",
    "CXB": "Cox's Bazar",
    "CXH": "Vancouver Harbour Flight Centre",
    "CXI": "Cassidy International",
    "CXJ": "Campo dos Bugres",
    "CXR": "Cam Ranh International",
    "CYA": "Les Cayes Airport",
    "CYB": "Charles Kirkconnell International",
    "CYP": "Calbayog",
    "CYS": "Cheyenne Regional",
    "CYZ": "Cauayan",
    "CZH": "Corozal",
    "CZL": "Mohamed Boudiaf International",
    "CZM": "Cozumel International",
    "CZS": "Cruzeiro do Sul International",
    "CZU": "Las Brujas",
    "CZX": "Changzhou Benniu",
    "DAB": "Daytona Beach International",
    "DAC": "Shahjalal International",
    "DAD": "Da Nang International",
    "DAL": "Dallas Love Field",
    "DAM": "Damascus International",
    "DAR": "Julius Nyerere International",
    "DAT": "Datong Yungang",
    "DAU": "Daru",
    "DAV": "Enrique Malek International",
    "DAY": "Dayton International",
    "DBB": "El Alamein International",
    "DBO": "Dubbo City Regional",
    "DBQ": "Dubuque Regional",
    "DBR": "Darbhanga Airport",
    "DBV": "Dubrovnik",
    "DCA": "Ronald Reagan Washington National",
    "DCM": "Castres–Mazamet",
    "DCY": "Daocheng Yading",
    "DDC": "Dodge City Regional",
    "DDD": "Dhaalu",
    "DDG": "Dandong Langtou",
    "DEB": "Debrecen International",
    "DEC": "Decatur",
    "DED": "Dehradun",
    "DEL": "Indira Gandhi International",
    "DEM": "Dembi Dolo",
    "DEN": "Denver International",
    "DEX": "Nop Goliat Dekai",
    "DFW": "Dallas/Fort Worth International",
    "DGA": "Dangriga",
    "DGE": "Mudgee",
    "DGH": "Deoghar Airport",
    "DGO": "Durango International",
    "DGT": "Sibulan",
    "DHI": "Dhangadhi",
    "DHM": "Dharamsala airport",
    "DHN": "Dothan Regional",
    "DIB": "Dibrugarh",
    "DIE": "Arrachart",
    "DIG": "Diqing Shangri-La",
    "DIK": "Dickinson Theodore Roosevelt Regional",
    "DIL": "Presidente Nicolau Lobato International",
    "DIN": "Dien Bien Phu",
    "DIQ": "Divinópolis",
    "DIR": "Aba Tenna Dejazmach Yilma International",
    "DIY": "Diyarbakır",
    "DJB": "Sultan Thaha",
    "DJE": "Djerba–Zarzis International",
    "DJG": "Tiska",
    "DJJ": "Sentani International",
    "DLA": "Douala International",
    "DLC": "Dalian Zhoushuizi International",
    "DLE": "Dole–Jura",
    "DLG": "Dillingham",
    "DLH": "Duluth International",
    "DLI": "Lien Khuong",
    "DLM": "Dalaman",
    "DLU": "Dali",
    "DLZ": "Dalanzadgad",
    "DMB": "Taraz",
    "DMD": "Doomadgee",
    "DME": "Domodedovo International",
    "DMK": "Don Mueang International",
    "DMM": "King Fahd International",
    "DMU": "Dimapur",
    "DND": "Dundee",
    "DNH": "Dunhuang",
    "DNZ": "Denizli Çardak",
    "DOB": "Dobo",
    "DOD": "Dodoma",
    "DOH": "Hamad International",
    "DOL": "Deauville – Normandie",
    "DOM": "Douglas–Charles",
    "DOY": "Dongying Shengli",
    "DPL": "Dipolog",
    "DPO": "Devonport",
    "DPS": "Ngurah Rai International",
    "DQA": "Daqing Sartu",
    "DQM": "Duqm Jaaluni",
    "DRK": "Drake Bay",
    "DRO": "Durango–La Plata County",
    "DRP": "Bicol International Airport",
    "DRS": "Dresden",
    "DRV": "Dharavandhoo",
    "DRW": "Darwin International",
    "DSE": "Combolcha",
    "DSM": "Des Moines International",
    "DSN": "Ordos Ejin Horo",
    "DSS": "Blaise Diagne International Airport",
    "DTB": "Silangit International",
    "DTM": "Dortmund",
    "DTW": "Detroit Metropolitan",
    "DUB": "Dublin",
    "DUD": "Dunedin",
    "DUE": "Dundo",
    "DUJ": "DuBois Regional",
    "DUR": "Durban International",
    "DUS": "Düsseldorf International Airport",
    "DUT": "Unalaska",
    "DVL": "Devils Lake Regional",
    "DVO": "Francisco Bangoy International",
    "DWC": "Al Maktoum International",
    "DWD": "Dawadmi Domestic",
    "DWO": "Kotte Diyawanna Oya SPB",
    "DXB": "Dubai International",
    "DYG": "Zhangjiajie Hehua International",
    "DYU": "Dushanbe International",
    "DZA": "Dzaoudzi–Pamandzi International",
    "DZN": "Zhezkazgan",
    "EAM": "Najran Regional Airport",
    "EAR": "Kearney Regional",
    "EAS": "San Sebastián",
    "EAT": "Pangborn Memorial",
    "EAU": "Chippewa Valley Regional",
    "EBA": "Marina di Campo",
    "EBB": "Entebbe International",
    "EBG": "El Bagre",
    "EBH": "El Bayadh",
    "EBJ": "Esbjerg",
    "EBL": "Erbil International",
    "ECN": "Ercan International",
    "ECP": "Northwest Florida Beaches International",
    "EDI": "Edinburgh Airport",
    "EDL": "Eldoret International",
    "EDO": "Balıkesir Koca Seyit",
    "EDR": "Edward River",
    "EEA": "Planalto Serrano Regional Airport",
    "EFL": "Kephalonia International",
    "EGC": "Bergerac Dordogne Périgord",
    "EGE": "Eagle County Regional",
    "EGM": "Seghe",
    "EGS": "Egilsstaðir",
    "EIN": "Eindhoven",
    "EIS": "Terrance B. Lettsome International",
    "EJA": "Yariguíes",
    "EKO": "Elko Regional",
    "ELC": "Elcho Island",
    "ELD": "South Arkansas Regional Airport at Goodwin Field",
    "ELG": "El Golea",
    "ELH": "North Eleuthera",
    "ELM": "Elmira/Corning Regional",
    "ELP": "El Paso International",
    "ELQ": "Prince Nayef bin Abdulaziz Regional",
    "ELS": "East London",
    "ELU": "Guemar",
    "EMA": "East Midlands",
    "EMD": "Emerald",
    "EMN": "Nema Airport",
    "ENE": "H. Hasan Aroeboesman",
    "ENH": "Enshi Xujiaping",
    "ENI": "El Nido",
    "ENU": "Akanu Ibiam International",
    "ENY": "Yan an Ershilipu",
    "EOH": "Olaya Herrera",
    "EOI": "Eday",
    "EPR": "Esperance",
    "EQS": "Esquel",
    "ERC": "Erzincan",
    "ERF": "Erfurt–Weimar",
    "ERH": "Moulay Ali Cherif",
    "ERI": "Erie International",
    "ERN": "Eirunepé Airport",
    "ERS": "Eros",
    "ERZ": "Erzurum",
    "ESB": "Esenboğa International",
    "ESC": "Delta County",
    "ESM": "Colonel Carlos Concha Torres",
    "ESR": "Ricardo Garcia Posada Airport",
    "ESU": "Essaouira-Mogador",
    "ETM": "Ramon Airport",
    "ETZ": "Metz–Nancy–Lorraine",
    "EUG": "Eugene",
    "EUN": "Hassan I",
    "EUQ": "Evelio Javier Airport",
    "EUX": "F. D. Roosevelt",
    "EVE": "Harstad/Narvik Airport, Evenes",
    "EVG": "Sveg",
    "EVN": "Zvartnots International",
    "EVV": "Evansville Regional",
    "EWB": "New Bedford Regional",
    "EWN": "Coastal Carolina Regional",
    "EWR": "Newark Liberty International",
    "EXT": "Exeter",
    "EYP": "El Alcaraván",
    "EYW": "Key West International",
    "EZE": "Ministro Pistarini International",
    "EZS": "Elazığ",
    "FAC": "Faaite",
    "FAE": "Vágar",
    "FAI": "Fairbanks International",
    "FAO": "Faro",
    "FAR": "Hector International",
    "FAT": "Fresno Yosemite International",
    "FAV": "Fakarava",
    "FAY": "Fayetteville Regional",
    "FBM": "Lubumbashi International",
    "FCA": "Glacier Park International",
    "FCO": "Leonardo da Vinci–Fiumicino Airport",
    "FDE": "Førde Airport, Bringeland",
    "FDF": "Martinique Aimé Césaire International",
    "FDH": "Friedrichshafen",
    "FEG": "Fergana International",
    "FEN": "Fernando De Noronha",
    "FEZ": "Fès–Saïs",
    "FGU": "Fangatau",
    "FHZ": "Fakahina Airfield",
    "FIH": "N djili",
    "FJR": "Fujairah International Airport",
    "FKB": "Karlsruhe/Baden-Baden",
    "FKQ": "Fakfak Torea",
    "FKS": "Fukushima",
    "FLA": "Gustavo Artunduaga Paredes",
    "FLG": "Flagstaff Pulliam",
    "FLL": "Fort Lauderdale–Hollywood International",
    "FLN": "Hercílio Luz International",
    "FLR": "Florence",
    "FLW": "Flores",
    "FLZ": "Ferdinand Lumban Tobing",
    "FMA": "Formosa International",
    "FMM": "Memmingen",
    "FMO": "Münster Osnabrück International",
    "FNA": "Lungi International",
    "FNC": "Cristiano Ronaldo International",
    "FND": "Funadhoo Airport",
    "FNI": "Nîmes–Alès–Camargue–Cévennes",
    "FNT": "Bishop International",
    "FOC": "Fuzhou Changle International",
    "FOD": "Fort Dodge Regional",
    "FOG": "Foggia \"Gino Lisa\"",
    "FON": "Fortuna Arenal",
    "FOR": "Pinto Martins – Fortaleza International",
    "FPO": "Grand Bahama International",
    "FRA": "Frankfurt International Airport",
    "FRE": "Fera",
    "FRL": "Forli Airport",
    "FRO": "Florø",
    "FRS": "Mundo Maya International",
    "FRU": "Manas International",
    "FRW": "Francistown",
    "FSC": "Figari–Sud Corse",
    "FSD": "Sioux Falls Regional",
    "FSM": "Fort Smith Regional",
    "FSP": "Saint-Pierre",
    "FSZ": "Shizuoka",
    "FTE": "Comandante Armando Tola International",
    "FTU": "Tôlanaro",
    "FUE": "Fuerteventura",
    "FUG": "Fuyang Xiguan",
    "FUJ": "Fukue",
    "FUK": "Fukuoka",
    "FUN": "Funafuti International",
    "FVM": "Fuvahmulah",
    "FWA": "Fort Wayne International",
    "FYJ": "Fuyuan Dongji",
    "FYN": "Fuyun Koktokay",
    "GAJ": "Yamagata",
    "GAN": "RAF Gan",
    "GAS": "Garissa Airport",
    "GAU": "Lokpriya Gopinath Bordoloi International",
    "GAY": "Gaya",
    "GBE": "Sir Seretse Khama International",
    "GBJ": "Les Bases Airport",
    "GCC": "Gillette–Campbell County",
    "GCI": "Guernsey",
    "GCK": "Garden City Regional",
    "GCM": "Owen Roberts International",
    "GDE": "Gode",
    "GDL": "Guadalajara International",
    "GDN": "Gdańsk Lech Wałęsa",
    "GDQ": "Gondar",
    "GDT": "JAGS McCartney International",
    "GDV": "Dawson Community",
    "GEA": "Nouméa Magenta",
    "GEG": "Spokane International",
    "GEL": "Sepé Tiaraju",
    "GEO": "Cheddi Jagan International",
    "GES": "General Santos International",
    "GET": "Geraldton",
    "GEV": "Gällivare",
    "GFF": "Griffith",
    "GFK": "Grand Forks International",
    "GGG": "East Texas Regional",
    "GGM": "Kakamega",
    "GGR": "Garowe",
    "GGT": "Exuma International",
    "GGW": "Glasgow",
    "GHA": "Noumérat – Moufdi Zakaria",
    "GHB": "Governor's Harbour",
    "GHV": "Brașov-Ghimbav International Airport",
    "GIB": "Gibraltar International",
    "GIC": "Boigu Island Airport",
    "GIG": "Rio de Janeiro–Galeão International",
    "GIL": "Gilgit",
    "GIS": "Gisborne",
    "GIU": "Sigiriya",
    "GIZ": "Jazan",
    "GJA": "La Laguna Airport",
    "GJL": "Jijel Ferhat Abbas",
    "GJT": "Grand Junction Regional",
    "GKA": "Goroka",
    "GKK": "Kooddoo",
    "GLA": "Glasgow",
    "GLF": "Golfito",
    "GLH": "Mid Delta Regional",
    "GLK": "Abdullahi Yusuf",
    "GLN": "Guelmim",
    "GLT": "Gladstone",
    "GMB": "Gambela",
    "GMO": "Gombe Lawanti International",
    "GMP": "Gimpo International",
    "GMQ": "Golog Maqin",
    "GMR": "Totegegie",
    "GMZ": "La Gomera",
    "GNB": "Alpes–Isère",
    "GND": "Maurice Bishop International",
    "GNJ": "Ganja International Airport",
    "GNM": "Guanambi Airport",
    "GNS": "Binaka",
    "GNV": "Gainesville Regional",
    "GNY": "Sanliurfa",
    "GOA": "Genoa Cristoforo Colombo",
    "GOB": "Robe Airport",
    "GOH": "Nuuk",
    "GOI": "Goa International",
    "GOJ": "Strigino International",
    "GOM": "Goma International",
    "GOP": "Mahayogi Gorakhnath",
    "GOQ": "Golmud",
    "GOT": "Göteborg Landvetter",
    "GOU": "Garoua International",
    "GOV": "Gove",
    "GOX": "Manohar International Airport",
    "GPA": "Araxos",
    "GPB": "Tancredo Thomaz de Faria",
    "GPI": "Guapi",
    "GPS": "Seymour",
    "GPT": "Gulfport–Biloxi International",
    "GRB": "Green Bay–Austin Straubel International",
    "GRI": "Central Nebraska Regional",
    "GRJ": "George",
    "GRK": "Killeen–Fort Hood Regional",
    "GRO": "Girona–Costa Brava",
    "GRQ": "Groningen Airport Eelde",
    "GRR": "Gerald R. Ford International",
    "GRU": "São Paulo–Guarulhos International",
    "GRV": "Grozny",
    "GRW": "Graciosa",
    "GRX": "Federico García Lorca",
    "GRZ": "Graz",
    "GSO": "Piedmont Triad International",
    "GSP": "Greenville–Spartanburg International",
    "GST": "Gustavus",
    "GTE": "Groote Eylandt",
    "GTF": "Great Falls International",
    "GTO": "Jalaluddin",
    "GTR": "Golden Triangle Regional",
    "GTZ": "Grumeti Kirawira B",
    "GUA": "La Aurora International",
    "GUC": "Gunnison–Crested Butte Regional",
    "GUM": "Antonio B. Won Pat International",
    "GUR": "Gurney",
    "GUW": "Atyrau",
    "GVA": "Geneva",
    "GVR": "Governador Valadares",
    "GWD": "Gwadar International",
    "GWL": "Gwalior",
    "GWT": "Sylt",
    "GXH": "Gannan Xiahe",
    "GYA": "Guayaramerín",
    "GYD": "Heydar Aliyev International",
    "GYE": "José Joaquín de Olmedo International",
    "GYN": "Santa Genoveva",
    "GYS": "Guangyuan Panlong",
    "GYU": "Guyuan Liupanshan",
    "GZG": "Garze Gesar Airport",
    "GZO": "Nusatupe",
    "GZP": "Gazipaşa",
    "GZT": "Oğuzeli",
    "HAA": "Hasvik",
    "HAC": "Hachijojima",
    "HAD": "Halmstad",
    "HAH": "Prince Said Ibrahim International",
    "HAJ": "Hannover",
    "HAK": "Haikou Meilan International",
    "HAM": "Hamburg",
    "HAN": "Noi Bai International",
    "HAQ": "Hanimaadhoo International",
    "HAS": "Ha il Regional",
    "HAU": "Haugesund Airport, Karmøy",
    "HBA": "Hobart International",
    "HBE": "Borg El Arab",
    "HBQ": "Haibei Qilian Airport",
    "HBX": "Hubli",
    "HBY": "Homa Bay Airport",
    "HCJ": "Hechi Jinchengjiang",
    "HDF": "Heringsdorf",
    "HDG": "Handan",
    "HDK": "Kulhudhuffushi Airport",
    "HDN": "Yampa Valley",
    "HDS": "Air Force Base Hoedspruit",
    "HDY": "Hat Yai International",
    "HEA": "Herat International",
    "HEH": "Heho",
    "HEK": "Heihe",
    "HEL": "Helsinki",
    "HER": "Heraklion International",
    "HET": "Hohhot Baita International",
    "HFE": "Hefei Xinqiao International",
    "HFS": "Hagfors",
    "HFT": "Hammerfest",
    "HGA": "Hargeisa",
    "HGD": "Hughenden",
    "HGH": "Hangzhou Xiaoshan International",
    "HGN": "Mae Hong Son",
    "HGO": "Korhogo",
    "HGR": "Hagerstown Regional",
    "HGU": "Mount Hagen",
    "HHH": "Hilton Head",
    "HHN": "Frankfurt–Hahn",
    "HHQ": "Hua Hin",
    "HHR": "Hawthorne Municipal",
    "HIA": "Huai an Lianshui",
    "HIB": "Range Regional",
    "HID": "Horn Island",
    "HIJ": "Hiroshima",
    "HIN": "Sacheon",
    "HIR": "Honiara International",
    "HIS": "Hayman Island Heliport",
    "HJJ": "Huaihua Zhijiang",
    "HJR": "Khajuraho",
    "HKD": "Hakodate",
    "HKG": "Hong Kong International",
    "HKK": "Hokitika",
    "HKN": "Hoskins",
    "HKR": "Mara North Airport",
    "HKT": "Phuket International",
    "HLA": "Lanseria International",
    "HLD": "Hulunbuir Hailar",
    "HLE": "Saint Helena",
    "HLH": "Ulanhot",
    "HLN": "Helena Regional",
    "HLP": "Halim Perdanakusuma International",
    "HLZ": "Hamilton",
    "HMB": "Sohag International",
    "HME": "Oued Irara–Krim Belkacem",
    "HMI": "Hami",
    "HMO": "General Ignacio Pesqueira García International",
    "HMV": "Hemavan",
    "HNA": "Hanamaki",
    "HND": "Haneda",
    "HNH": "Hoonah",
    "HNL": "Daniel K. Inouye International",
    "HNM": "Hana",
    "HNS": "Haines",
    "HNY": "Hengyang Nanyue",
    "HOB": "Lea County Regional",
    "HOE": "Ban Huoeisay",
    "HOF": "Al-Ahsa International",
    "HOI": "Hao",
    "HOM": "Homer",
    "HOR": "Horta",
    "HOT": "Memorial Field",
    "HOU": "William P. Hobby",
    "HOV": "Ørsta–Volda Airport, Hovden",
    "HOX": "Homalin",
    "HPH": "Cat Bi International",
    "HPN": "Westchester County",
    "HRB": "Harbin Taiping International",
    "HRE": "Harare International",
    "HRF": "Hoarafushi Airport",
    "HRG": "Hurghada International",
    "HRL": "Valley International",
    "HRO": "Boone County",
    "HSA": "Turkistan International Airport",
    "HSG": "Saga",
    "HSN": "Zhoushan Putuoshan",
    "HSR": "Rajkot International Airport",
    "HSV": "Huntsville International",
    "HTA": "Kadala",
    "HTI": "Great Barrier Reef",
    "HTN": "Hotan",
    "HTS": "Tri-State",
    "HTT": "Huatugou Airport",
    "HTY": "Hatay",
    "HUE": "Humera",
    "HUH": "Huahine – Fare",
    "HUI": "Phu Bai International",
    "HUU": "Alférez FAP David Figueroa Fernandini",
    "HUX": "Bahías de Huatulco International",
    "HUY": "Humberside",
    "HUZ": "Huizhou Pingtan",
    "HVB": "Hervey Bay",
    "HVD": "Khovd",
    "HVG": "Honningsvåg Airport, Valan",
    "HVN": "Tweed New Haven",
    "HVR": "Havre City–County",
    "HXD": "Delingha Airport",
    "HYA": "Barnstable Municipal",
    "HYD": "Rajiv Gandhi International",
    "HYN": "Taizhou Luqiao",
    "HYS": "Hays Regional",
    "HZG": "Hanzhong Xiguan",
    "IAD": "Washington Dulles International",
    "IAG": "Niagara Falls International",
    "IAH": "George Bush Intercontinental",
    "IAL": "Salinas Airport",
    "IAM": "In Amenas",
    "IAO": "Sayak",
    "IAS": "Iași International",
    "IBA": "Ibadan",
    "IBB": "General Villamil Airport",
    "IBE": "Perales",
    "IBR": "Ibaraki",
    "IBZ": "Ibiza",
    "ICI": "Cicia",
    "ICN": "Incheon International",
    "ICT": "Wichita Dwight D. Eisenhower National",
    "IDA": "Idaho Falls Regional",
    "IDR": "Devi Ahilya Bai Holkar",
    "IEG": "Zielona Góra",
    "IFJ": "Ísafjörður",
    "IFN": "Isfahan International",
    "IFU": "Ifuru",
    "IGA": "Inagua",
    "IGD": "Iğdır",
    "IGR": "Cataratas del Iguazú International",
    "IGU": "Foz do Iguaçu International",
    "IKA": "Tehran Imam Khomeini International",
    "IKI": "Iki",
    "IKT": "International Airport Irkutsk",
    "ILD": "Lleida–Alguaire",
    "ILM": "Wilmington International",
    "ILO": "Iloilo International",
    "ILP": "Île des Pins",
    "ILR": "Ilorin International",
    "ILY": "Islay",
    "IMF": "Imphal International",
    "IMP": "Imperatriz",
    "IMT": "Ford",
    "INB": "Independence",
    "INC": "Yinchuan Hedong International",
    "IND": "Indianapolis International",
    "INF": "In Guezzam Airport",
    "INH": "Inhambane",
    "INI": "Niš Constantine the Great",
    "INL": "Falls International",
    "INN": "Innsbruck",
    "INU": "Nauru International",
    "INV": "Inverness",
    "INZ": "In Salah",
    "IOA": "Ioannina National",
    "IOM": "Isle of Man",
    "IOS": "Ilhéus Jorge Amado",
    "IPC": "Mataveri International",
    "IPH": "Sultan Azlan Shah",
    "IPI": "San Luis",
    "IPL": "Imperial County",
    "IPN": "Usiminas",
    "IPT": "Williamsport Regional",
    "IQN": "Qingyang",
    "IQQ": "Diego Aracena International",
    "IQT": "Coronel FAP Francisco Secada Vignetta International",
    "IRA": "Kirakira",
    "IRG": "Lockhart River",
    "IRI": "Iringa Airport",
    "IRJ": "La Rioja",
    "IRK": "Kirksville Regional",
    "IRZ": "Tapuruquara Airport",
    "ISA": "Mount Isa",
    "ISB": "Islamabad International Airport",
    "ISE": "Isparta Süleyman Demirel",
    "ISG": "Ishigaki",
    "ISK": "Gandhinagar Airport",
    "ISP": "Long Island MacArthur",
    "IST": "Istanbul Airport",
    "ISU": "Sulaimaniyah International",
    "ITB": "Itaituba",
    "ITH": "Ithaca Tompkins Regional",
    "ITM": "Itami",
    "ITO": "Hilo International",
    "IUE": "Niue International",
    "IVC": "Invercargill",
    "IVL": "Ivalo",
    "IVR": "Inverell",
    "IWD": "Gogebic–Iron County",
    "IWJ": "Iwami",
    "IXA": "Agartala",
    "IXB": "Bagdogra",
    "IXC": "Chandigarh International",
    "IXD": "Allahabad",
    "IXE": "Mangaluru",
    "IXG": "Belgaum",
    "IXJ": "Jammu",
    "IXL": "Kushok Bakula Rimpochee",
    "IXM": "Madurai",
    "IXR": "Birsa Munda",
    "IXS": "Silchar",
    "IXU": "Aurangabad",
    "IXY": "Kandla",
    "IXZ": "Veer Savarkar International",
    "IZA": "Zona da Mata Regional",
    "IZO": "Izumo",
    "JAC": "Jackson Hole",
    "JAI": "Jaipur International",
    "JAN": "Jackson–Evers International",
    "JAU": "Francisco Carle",
    "JAV": "Ilulissat",
    "JAW": "Araripina Airport",
    "JAX": "Jacksonville International",
    "JBQ": "La Isabela International",
    "JBR": "Jonesboro Municipal",
    "JCK": "Julia Creek",
    "JCS": "Crateús Airport",
    "JDH": "Jodhpur",
    "JDO": "Juazeiro do Norte",
    "JDZ": "Jingdezhen Luojia",
    "JED": "King Abdulaziz International",
    "JEK": "Lower Zambezi Nat.Park Jeki",
    "JER": "Jersey",
    "JFK": "John F. Kennedy International",
    "JGA": "Jamnagar",
    "JGD": "Jiagedaqi",
    "JGN": "Jiayuguan",
    "JGS": "Jinggangshan",
    "JHB": "Senai International",
    "JHG": "Xishuangbanna Gasa",
    "JHM": "Kapalua",
    "JIA": "Juína Airport",
    "JIB": "Djibouti–Ambouli International",
    "JIC": "Jinchang Jinchuan",
    "JIJ": "Jijiga",
    "JIK": "Ikaria Island National",
    "JIM": "Aba Segud",
    "JIQ": "Qianjiang Wulingshan",
    "JIU": "Jiujiang Lushan Airport",
    "JJD": "Cruz Jericoacoara",
    "JJG": "Jaguaruna Regional",
    "JJM": "Meru-Kinna Mulika Lodge",
    "JJN": "Quanzhou Jinjiang International",
    "JKH": "Chios Island National",
    "JKL": "Kalymnos Island National",
    "JKR": "Janakpur",
    "JLN": "Joplin Regional",
    "JLR": "Jabalpur",
    "JMA": "Manhuacu Elias Breder Airport",
    "JMJ": "Lancang Jingmai Airport",
    "JMK": "Mykonos Island National",
    "JMS": "Jamestown Regional",
    "JMU": "Jiamusi Dongjiao",
    "JNB": "O. R. Tambo International",
    "JNG": "Jining Qufu",
    "JNU": "Juneau International",
    "JNX": "Naxos Island National",
    "JNZ": "Jinzhou Xiaolingzi",
    "JOE": "Joensuu",
    "JOG": "Adisutjipto International",
    "JOI": "Joinville-Lauro Carneiro de Loyola",
    "JPA": "Presidente Castro Pinto International",
    "JPO": "Patos Airport",
    "JPR": "Ji-Paraná",
    "JRG": "Jharsuguda Airport",
    "JRO": "Kilimanjaro International",
    "JSA": "Jaisalmer",
    "JSB": "São Benedito Airport",
    "JSH": "Sitia Public",
    "JSI": "Skiathos Island National",
    "JSJ": "Jiansanjiang",
    "JSO": "Sobral Airport",
    "JSR": "Jessore",
    "JST": "Johnstown–Cambria County",
    "JSY": "Syros Island National",
    "JTC": "Bauru-Arealva",
    "JTR": "Santorini (Thira) National",
    "JTY": "Astypalaia Island National",
    "JUB": "Juba International",
    "JUH": "Chizhou Jiuhuashan",
    "JUJ": "Gobernador Horacio Guzmán International",
    "JUL": "Inca Manco Cápac International",
    "JUZ": "Quzhou",
    "JXA": "Jixi Xingkaihu",
    "JYV": "Jyvaskyla",
    "JZH": "Jiuzhai Huanglong",
    "KAA": "Kasama",
    "KAD": "Kaduna International",
    "KAE": "Kake Sea Plane Base",
    "KAJ": "Kajaani",
    "KAN": "Mallam Aminu Kano International",
    "KAO": "Kuusamo",
    "KAW": "Kawthaung",
    "KAZ": "Kaubang",
    "KBH": "Kahama Buzwagi",
    "KBL": "Hamid Karzai International",
    "KBR": "Sultan Ismail Petra",
    "KBU": "Gusti Syamsir Alam",
    "KBV": "Krabi",
    "KCA": "Kuqa Qiuci",
    "KCH": "Kuching International",
    "KCM": "Kahramanmaraş",
    "KCT": "Koggala",
    "KCZ": "Kōchi",
    "KDH": "Kandahar International",
    "KDI": "Haluoleo",
    "KDM": "Kaadedhdhoo",
    "KDO": "Kadhdhoo",
    "KDU": "Skardu",
    "KDV": "Vunisea",
    "KDW": "Victoria Reservoir SPB",
    "KEF": "Keflavík International",
    "KEM": "Kemi-Tornio",
    "KEO": "Odienne",
    "KEP": "Nepalgunj",
    "KER": "Kerman",
    "KET": "Kengtung",
    "KEU": "Maasai Mara Keekorok",
    "KEW": "Keewaywin",
    "KFA": "Kiffa Airport",
    "KFS": "Kastamonu",
    "KGC": "Kingscote",
    "KGE": "Kaghau",
    "KGF": "Sary-Arka",
    "KGI": "Kalgoorlie-Boulder",
    "KGL": "Kigali International",
    "KGS": "Kos Island International",
    "KGT": "Kangding",
    "KHG": "Kashi",
    "KHH": "Kaohsiung International",
    "KHI": "Jinnah International",
    "KHM": "Khamti",
    "KHN": "Nanchang Changbei International",
    "KHS": "Khasab",
    "KHT": "Khost Airport",
    "KHV": "Khabarovsk Novy",
    "KHX": "Kihihi Airstrip",
    "KHZ": "Kauehi",
    "KID": "Kristianstad",
    "KIE": "Aropa",
    "KIF": "Kingfisher Lake",
    "KIH": "Kish International",
    "KIJ": "Niigata",
    "KIK": "Kirkuk Air Base",
    "KIM": "Kimberley",
    "KIN": "Norman Manley International",
    "KIR": "Kerry",
    "KIS": "Kisumu International",
    "KIT": "Kithira Island National",
    "KIX": "Kansai International",
    "KJA": "Yemelyanovo International",
    "KJB": "Kurnool Airport",
    "KJI": "Kanas",
    "KJT": "Kertajati International",
    "KKC": "Khon Kaen",
    "KKE": "Kerikeri",
    "KKJ": "Kitakyushu",
    "KKN": "Kirkenes Airport, Høybuktmoen",
    "KKR": "Kaukura",
    "KKX": "Kikai",
    "KLH": "Kolhapur Airport",
    "KLO": "Kalibo International",
    "KLR": "Kalmar",
    "KLU": "Klagenfurt",
    "KLV": "Karlovy Vary",
    "KLW": "Klawock",
    "KLX": "Kalamata International",
    "KME": "Kamembe",
    "KMG": "Kunming Changshui International",
    "KMI": "Miyazaki",
    "KMJ": "Kumamoto",
    "KMK": "Makabana Airport",
    "KMQ": "Komatsu",
    "KMS": "Kumasi",
    "KMU": "Kisimayu",
    "KMV": "Kalaymyo",
    "KNG": "Kaimana",
    "KNH": "Kinmen",
    "KNO": "Kualanamu International",
    "KNS": "King Island",
    "KNU": "Kanpur",
    "KNX": "East Kimberley Regional",
    "KOA": "Kona International",
    "KOE": "El Tari International",
    "KOI": "Kirkwall",
    "KOJ": "Kagoshima",
    "KOK": "Kokkola-Pietarsaari",
    "KOP": "Nakhon Phanom",
    "KOS": "Sihanoukville International",
    "KOV": "Kokshetau",
    "KOW": "Ganzhou Huangjin",
    "KPO": "Pohang",
    "KRF": "Höga Kusten",
    "KRK": "John Paul II International Airport Kraków–Balice",
    "KRL": "Korla",
    "KRN": "Kiruna",
    "KRP": "Midtjyllands",
    "KRS": "Kristiansand Airport, Kjevik",
    "KRW": "Turkmenbashi",
    "KRY": "Karamay",
    "KSA": "Kosrae International",
    "KSC": "Košice International",
    "KSE": "Kasese",
    "KSF": "Kassel",
    "KSJ": "Kasos Island Public",
    "KSM": "St. Mary s",
    "KSN": "Kostanay",
    "KSO": "Kastoria National",
    "KSQ": "Karshi",
    "KSU": "Kristiansund Airport, Kvernberget",
    "KSY": "Kars Harakani",
    "KTA": "Karratha",
    "KTD": "Kitadaito",
    "KTG": "Ketapang Rahadi Osman",
    "KTJ": "Kichwa Tembo Airstrip",
    "KTL": "Kitale",
    "KTM": "Tribhuvan International",
    "KTN": "Ketchikan International",
    "KTR": "RAAF Base Tindal",
    "KTT": "Kittilä",
    "KTW": "Katowice International",
    "KUA": "Sultan Haji Ahmad Shah",
    "KUF": "Kurumoch International",
    "KUG": "Kubin Island",
    "KUH": "Kushiro",
    "KUL": "Kuala Lumpur International",
    "KUM": "Yakushima",
    "KUN": "Kaunas",
    "KUO": "Kuopio",
    "KUS": "Kulusuk",
    "KUT": "David the Builder Kutaisi International",
    "KUV": "Gunsan",
    "KVA": "Kavala International",
    "KVG": "Kavieng",
    "KVO": "Morava Airport",
    "KWA": "Bucholz Army Airfield",
    "KWE": "Guiyang Longdongbao International",
    "KWI": "Kuwait International",
    "KWJ": "Gwangju",
    "KWL": "Guilin Liangjiang International",
    "KWM": "Kowanyama",
    "KXF": "Koro Island Airport",
    "KXU": "Katiu",
    "KYA": "Konya",
    "KYP": "Kyaukpyu",
    "KZI": "Kozani National",
    "KZN": "Kazan International",
    "KZO": "Kyzylorda",
    "KZR": "Zafer",
    "KZS": "Kastellorizo",
    "LAD": "Quatro de Fevereiro",
    "LAE": "Lae Nadzab",
    "LAF": "Purdue University Airport",
    "LAH": "Oesman Sadik",
    "LAN": "Capital Region International",
    "LAO": "Laoag International",
    "LAP": "La Paz International",
    "LAR": "Laramie Regional",
    "LAS": "Harry Reid International Airport",
    "LAU": "Manda",
    "LAW": "Lawton–Fort Sill Regional",
    "LAX": "Los Angeles International",
    "LBA": "Leeds Bradford",
    "LBB": "Lubbock Preston Smith International",
    "LBC": "Lubeck Blankensee Airport",
    "LBD": "Khujand",
    "LBE": "Arnold Palmer Regional",
    "LBF": "North Platte Regional",
    "LBJ": "Komodo",
    "LBL": "Liberal Mid-America Regional",
    "LBP": "Long Banga",
    "LBR": "Lábrea Airport",
    "LBS": "Labasa",
    "LBU": "Labuan",
    "LBV": "Libreville International",
    "LCA": "Larnaca International",
    "LCE": "Golosón International",
    "LCG": "A Coruña",
    "LCH": "Lake Charles Regional",
    "LCJ": "Łódź Władysław Reymont",
    "LCK": "Rickenbacker International",
    "LCR": "La Chorrera",
    "LCX": "Longyan Guanzhishan",
    "LCY": "London City",
    "LDB": "Londrina",
    "LDE": "Tarbes–Lourdes–Pyrénées",
    "LDH": "Lord Howe Island",
    "LDS": "Yichun Lindu",
    "LDU": "Lahad Datu",
    "LDY": "City of Derry",
    "LEA": "RAAF Base Learmonth",
    "LEB": "Lebanon Municipal",
    "LEC": "Lençóis",
    "LED": "Pulkovo International",
    "LEI": "Almería",
    "LEJ": "Leipzig/Halle Airport",
    "LEN": "León",
    "LET": "Alfredo Vásquez Cobo International",
    "LEU": "Andorra–La Seu d'Urgell Airport",
    "LEX": "Blue Grass",
    "LFQ": "Linfen Qiaoli",
    "LFT": "Lafayette Regional",
    "LFW": "Lomé–Tokoin International",
    "LGA": "LaGuardia",
    "LGB": "Long Beach",
    "LGG": "Liège",
    "LGI": "Deadman's Cay",
    "LGK": "Langkawi International",
    "LGL": "Long Lellang",
    "LGW": "Gatwick",
    "LHE": "Allama Iqbal International",
    "LHN": "Linhares Municipal Airport",
    "LHR": "Heathrow",
    "LHW": "Lanzhou Zhongchuan International",
    "LIF": "Ouanaham",
    "LIG": "Limoges – Bellegarde",
    "LIH": "Lihue",
    "LIL": "Lille",
    "LIM": "Jorge Chávez International",
    "LIN": "Linate",
    "LIO": "Limón International",
    "LIR": "Daniel Oduber Quirós International",
    "LIS": "Lisbon Portela",
    "LIT": "Clinton National",
    "LJG": "Lijiang Sanyi International",
    "LJU": "Ljubljana Jože Pučnik",
    "LKA": "Gewayantana",
    "LKB": "Lakeba",
    "LKE": "Kenmore Air Harbor Seaplane Base",
    "LKH": "Long Akah",
    "LKL": "Lakselv Airport, Banak",
    "LKN": "Leknes",
    "LKO": "Chaudhary Charan Singh International",
    "LKY": "Lake Manyara",
    "LLA": "Luleå",
    "LLI": "Lalibela",
    "LLJ": "Silampari Airport",
    "LLO": "Palopo Lagaligo",
    "LLV": "Lüliang",
    "LLW": "Lilongwe International",
    "LMC": "La Macarena",
    "LMM": "Los Mochis International",
    "LMN": "Limbang",
    "LMP": "Lampedusa",
    "LMU": "Letung Airport",
    "LMV": "Madivaru Airport",
    "LNJ": "Lincang",
    "LNK": "Lincoln",
    "LNL": "Longnan Chengzhou Airport",
    "LNS": "Lancaster",
    "LNU": "Robert Atty Bessing Airport",
    "LNV": "Lihir Island",
    "LNY": "Lanai",
    "LNZ": "Linz",
    "LOE": "Loei",
    "LOK": "Lodwar",
    "LOO": "L Mekrareg",
    "LOP": "Lombok International",
    "LOS": "Murtala Muhammed International",
    "LPA": "Gran Canaria",
    "LPB": "El Alto International",
    "LPD": "La Pedrera",
    "LPF": "Liupanshui Yuezhao",
    "LPI": "Linköping City",
    "LPL": "Liverpool John Lennon",
    "LPP": "Lappeenranta",
    "LPQ": "Luang Prabang International",
    "LPT": "Lampang",
    "LPY": "Le Puy – Loudes",
    "LQM": "Caucayá",
    "LRD": "Laredo International",
    "LRE": "Longreach",
    "LRH": "La Rochelle – Île de Ré",
    "LRM": "La Romana International",
    "LRR": "Larestan International",
    "LRS": "Leros Municipal",
    "LRT": "Lorient South Brittany",
    "LRU": "Las Cruces International Airport",
    "LSA": "Losuia",
    "LSC": "La Florida",
    "LSE": "La Crosse Regional",
    "LSH": "Lashio",
    "LSI": "Sumburgh",
    "LSS": "Terre-de-Haut Airport",
    "LST": "Launceston",
    "LSW": "Malikus Saleh",
    "LTI": "Altai",
    "LTN": "Luton",
    "LTO": "Loreto International",
    "LUD": "Lüderitz",
    "LUM": "Dehong Mangshi",
    "LUN": "Kenneth Kaunda International",
    "LUO": "Luena",
    "LUP": "Kalaupapa Airport",
    "LUQ": "Brigadier Mayor César Raúl Ojeda",
    "LUV": "Karel Sadsuitubun Airport",
    "LUW": "Bubung",
    "LUX": "Luxembourg",
    "LUZ": "Lublin",
    "LVI": "Harry Mwanga Nkumbula International",
    "LWB": "Greenbrier Valley",
    "LWE": "Wonopito",
    "LWS": "Lewiston–Nez Perce County",
    "LWY": "Lawas",
    "LXA": "Lhasa Gonggar",
    "LXG": "Louang Namtha",
    "LXR": "Luxor International",
    "LXS": "Lemnos International",
    "LYA": "Luoyang Beijiao",
    "LYB": "Edward Bodden Airfield",
    "LYC": "Lycksele",
    "LYG": "Lianyungang Baitabu",
    "LYI": "Linyi Shubuling",
    "LYP": "Faisalabad International",
    "LYR": "Svalbard Airport, Longyear",
    "LYS": "Lyon–Saint-Exupéry",
    "LZH": "Liuzhou Bailian",
    "LZO": "Luzhou Yunlong",
    "LZY": "Nyingchi Mainling",
    "MAA": "Chennai International",
    "MAB": "Marabá",
    "MAD": "Adolfo Suárez Madrid–Barajas",
    "MAF": "Midland International Air and Space Port",
    "MAG": "Madang",
    "MAH": "Menorca",
    "MAJ": "Marshall Islands International",
    "MAM": "Matamoros International",
    "MAN": "Manchester",
    "MAO": "Eduardo Gomes International",
    "MAQ": "Mae Sot",
    "MAR": "La Chinita International",
    "MAS": "Momote",
    "MAU": "Maupiti",
    "MAZ": "Eugenio María de Hostos",
    "MBA": "Moi International",
    "MBE": "Monbetsu",
    "MBI": "Mbeya",
    "MBJ": "Sangster International",
    "MBL": "Manistee County Blacker",
    "MBQ": "Mbarara Airport",
    "MBS": "MBS International",
    "MBT": "Moises R. Espinosa",
    "MBZ": "Maués Airport",
    "MCE": "Merced Regional",
    "MCI": "Kansas City International",
    "MCK": "McCook Ben Nelson Regional",
    "MCN": "Middle Georgia Regional",
    "MCO": "Orlando International",
    "MCP": "Macapá International",
    "MCT": "Muscat International",
    "MCV": "McArthur River Mine",
    "MCW": "Mason City Municipal",
    "MCX": "Uytash",
    "MCY": "Sunshine Coast",
    "MCZ": "Zumbi dos Palmares International",
    "MDC": "Sam Ratulangi International",
    "MDE": "José María Córdova International",
    "MDG": "Mudanjiang Hailang International",
    "MDI": "Makurdi Airport",
    "MDL": "Mandalay International",
    "MDQ": "Astor Piazzolla International",
    "MDT": "Harrisburg International",
    "MDU": "Mendi",
    "MDW": "Chicago Midway International",
    "MDZ": "Governor Francisco Gabrielli International",
    "MEC": "Eloy Alfaro International",
    "MED": "Prince Mohammad bin Abdulaziz",
    "MEE": "Mare Airport",
    "MEH": "Mehamn",
    "MEI": "Meridian Regional",
    "MEL": "Melbourne",
    "MEM": "Memphis International",
    "MEQ": "Cut Nyak Dhien",
    "MEU": "Monte Dourado Airport",
    "MEX": "Mexico City International",
    "MFA": "Mafia",
    "MFE": "McAllen Miller International",
    "MFM": "Macau International",
    "MFR": "Rogue Valley International–Medford",
    "MFU": "Mfuwe",
    "MGA": "Augusto C. Sandino International",
    "MGB": "Mount Gambier",
    "MGF": "Maringá Regional",
    "MGH": "Margate",
    "MGM": "Montgomery Regional",
    "MGQ": "Aden Adde International",
    "MGS": "Mangaia Island Airport",
    "MGT": "Milingimbi",
    "MGW": "Morgantown Municipal",
    "MGZ": "Myeik",
    "MHC": "Mocopulli",
    "MHD": "Mashhad International",
    "MHG": "Mannheim City",
    "MHH": "Marsh Harbour",
    "MHK": "Manhattan Regional",
    "MHQ": "Mariehamn",
    "MHT": "Manchester–Boston Regional",
    "MHX": "Manihiki Island Airport",
    "MIA": "Miami International",
    "MID": "Mérida International",
    "MIG": "Mianyang Nanjiao",
    "MII": "Marília",
    "MIM": "Merimbula",
    "MIR": "Monastir Habib Bourguiba International",
    "MIS": "Misima Island",
    "MIU": "Maiduguri International Airport",
    "MJC": "Man",
    "MJF": "Mosjøen Airport, Kjærstad",
    "MJI": "Mitiga International",
    "MJK": "Shark Bay",
    "MJN": "Amborovy",
    "MJT": "Mytilene International",
    "MJU": "Tampa Padang",
    "MKE": "General Mitchell International",
    "MKG": "Muskegon County",
    "MKK": "Molokai",
    "MKL": "McKellar–Sipes Regional",
    "MKM": "Mukah",
    "MKP": "Makemo",
    "MKQ": "Mopah International",
    "MKW": "Rendani",
    "MKY": "Mackay",
    "MKZ": "Malacca International",
    "MLA": "Malta International",
    "MLB": "Orlando Melbourne International",
    "MLE": "Velana International",
    "MLG": "Abdul Rachman Saleh",
    "MLH": "Mulhouse/Basel EuroAirport French FR",
    "MLI": "Quad City International",
    "MLM": "Morelia International",
    "MLN": "Melilla",
    "MLO": "Milos Island National",
    "MLU": "Monroe Regional",
    "MLX": "Malatya Erhaç",
    "MMB": "Memanbetsu",
    "MMD": "Minami-Daito",
    "MME": "Durham Tees Valley",
    "MMH": "Mammoth Yosemite",
    "MMJ": "Matsumoto",
    "MMO": "Maio",
    "MMX": "Malmö",
    "MMY": "Miyako",
    "MNC": "Nacala",
    "MNG": "Maningrida",
    "MNL": "Ninoy Aquino International",
    "MNS": "Mansa Airport",
    "MNX": "Manicoré Airport",
    "MNY": "Mono Airport",
    "MOB": "Mobile Regional",
    "MOC": "Montes Claros",
    "MOF": "Frans Xavier Seda",
    "MOG": "Monghsat",
    "MOH": "Maleo Airport",
    "MOI": "Mitiaro Island Airport",
    "MOL": "Molde Airport, Årø",
    "MOQ": "Morondava",
    "MOT": "Minot International",
    "MOV": "Moranbah",
    "MOZ": "Moorea",
    "MPA": "Katima Mulilo",
    "MPH": "Godofredo P. Ramos",
    "MPL": "Montpellier–Méditerranée",
    "MPM": "Maputo International",
    "MPN": "RAF Mount Pleasant",
    "MQL": "Mildura",
    "MQM": "Mardin",
    "MQN": "Mo i Rana Airport, Røssvoll",
    "MQP": "Kruger Mpumalanga International",
    "MQT": "Sawyer International",
    "MQX": "Alula Aba Nega",
    "MRA": "Misrata",
    "MRE": "Mara Serena",
    "MRS": "Marseille Provence",
    "MRU": "Sir Seewoosagur Ramgoolam International",
    "MRV": "Mineralnye Vody",
    "MRY": "Monterey Regional",
    "MRZ": "Moree",
    "MSA": "Muskrat Dam",
    "MSH": "Masirah Air Base",
    "MSJ": "Misawa",
    "MSL": "Northwest Alabama Regional",
    "MSN": "Dane County Regional",
    "MSO": "Missoula International",
    "MSP": "Minneapolis–Saint Paul International",
    "MSQ": "Minsk National",
    "MSR": "Muş",
    "MSS": "Massena International",
    "MST": "Maastricht Aachen",
    "MSU": "Mejametalana",
    "MSY": "Louis Armstrong New Orleans International",
    "MSZ": "Namibe",
    "MTB": "Montelibano Airport",
    "MTJ": "Montrose Regional",
    "MTR": "Los Garzones",
    "MTT": "Minatitlán/Coatzacoalcos International",
    "MTY": "Monterrey International",
    "MUA": "Munda",
    "MUB": "Maun",
    "MUC": "Munich",
    "MUE": "Waimea-Kohala",
    "MUH": "Mersa Matruh Airport",
    "MUK": "Mauke Airport",
    "MUR": "Marudi",
    "MUX": "Multan International",
    "MVB": "M Vengue El Hadj Omar Bongo Ondimba International",
    "MVD": "Carrasco International",
    "MVF": "Mossoro",
    "MVP": "Fabio Alberto León Bentley",
    "MVR": "Salak",
    "MVT": "Mataiva",
    "MVY": "Martha's Vineyard",
    "MWA": "Williamson County Regional",
    "MWX": "Muan International",
    "MWZ": "Mwanza",
    "MXH": "Moro",
    "MXL": "Mexicali International",
    "MXP": "Milan–Malpensa",
    "MXV": "Mörön",
    "MXX": "Mora–Siljan",
    "MXZ": "Meixian",
    "MYA": "Moruya",
    "MYD": "Malindi",
    "MYG": "Mayaguana",
    "MYI": "Murray Island",
    "MYJ": "Matsuyama",
    "MYP": "Mary International",
    "MYR": "Myrtle Beach International",
    "MYT": "Myitkyina",
    "MYW": "Mtwara",
    "MYY": "Miri",
    "MZA": "Mayor Pnp Nancy Flore Airport",
    "MZG": "Magong",
    "MZH": "Amasya Merzifon",
    "MZL": "La Nubia",
    "MZR": "Mazar-i-Sharif International",
    "MZT": "Mazatlán International",
    "MZV": "Mulu",
    "MZW": "Mecheria Airport",
    "NAA": "Narrabri",
    "NAG": "Dr. Babasaheb Ambedkar International",
    "NAJ": "Nakhchivan International",
    "NAM": "Namlea Airport",
    "NAN": "Nadi International",
    "NAO": "Nanchong Gaoping",
    "NAP": "Naples International",
    "NAS": "Lynden Pindling International",
    "NAT": "Greater Natal International Airport",
    "NAV": "Nevşehir Kapadokya",
    "NAW": "Narathiwat",
    "NBE": "Enfidha–Hammamet International",
    "NBO": "Jomo Kenyatta International",
    "NBS": "Changbaishan",
    "NBX": "Nabire",
    "NCE": "Nice Côte d Azur International",
    "NCL": "Newcastle",
    "NCU": "Nukus",
    "NDB": "Nouadhibou International",
    "NDG": "Qiqihar Sanjiazi",
    "NDJ": "N Djamena International",
    "NDR": "Nador International",
    "NDU": "Rundu",
    "NDY": "Sanday",
    "NEK": "Nekemte Airport",
    "NEU": "Nathong",
    "NEV": "Vance W. Amory International",
    "NGB": "Ningbo Lishe International",
    "NGE": "Ngaoundéré",
    "NGO": "Chubu Centrair International",
    "NGQ": "Ngari Gunsa",
    "NGS": "Nagasaki",
    "NHV": "Nuku Hiva",
    "NIM": "Diori Hamani International",
    "NIU": "Niau",
    "NJC": "Nizhnevartovsk",
    "NJF": "Al Najaf International",
    "NKC": "Nouakchott International",
    "NKG": "Nanjing Lukou International",
    "NKM": "Nagoya Airfield",
    "NKT": "Şırnak",
    "NLA": "Simon Mwansa Kapwepwe International",
    "NLD": "Nuevo Laredo International",
    "NLF": "Darnley Island Airport",
    "NLH": "Ninglang Luguhu",
    "NLK": "Norfolk Island",
    "NLT": "Xinyuan Nalati",
    "NLU": "Felipe Ángeles International Airport",
    "NMA": "Namangan",
    "NMF": "Maafaru International Airport",
    "NNB": "Santa Ana",
    "NNG": "Nanning Wuxu International",
    "NNT": "Nan Nakhon",
    "NNY": "Nanyang Jiangying",
    "NOB": "Nosara",
    "NOC": "Ireland West Airport Knock",
    "NOP": "Sinop",
    "NOS": "Fascene",
    "NOU": "La Tontouta International",
    "NOV": "Albano Machado",
    "NPE": "Hawke's Bay",
    "NPL": "New Plymouth",
    "NPY": "Mpanda Airport",
    "NQN": "Presidente Perón International",
    "NQU": "Reyes Murillo",
    "NQY": "Cornwall Airport Newquay",
    "NQZ": "Nursultan Nazarbayev International Airport",
    "NRA": "Narrandera",
    "NRK": "Norrköping",
    "NRL": "North Ronaldsay",
    "NRN": "Weeze",
    "NRR": "José Aponte de la Torre",
    "NRT": "Narita International",
    "NSB": "North Bimini Airport",
    "NSI": "Yaoundé Nsimalen International",
    "NSN": "Nelson",
    "NSR": "São Raimundo Nonato Airport",
    "NST": "Nakhon Si Thammarat",
    "NTE": "Nantes Atlantique",
    "NTG": "Nantong Xingdong",
    "NTL": "Newcastle",
    "NTN": "Normanton",
    "NTQ": "Noto",
    "NTX": "Ranai",
    "NUE": "Nuremberg",
    "NUF": "Hatton Castlereigh Resrvr SPB",
    "NUM": "Neom Bay Airport",
    "NVA": "Benito Salas",
    "NVI": "Navoi International",
    "NVT": "Navegantes",
    "NWI": "Norwich",
    "NYC": "New York City",
    "NYK": "Nanyuki",
    "NYO": "Stockholm Skavsta",
    "NYT": "Naypyidaw",
    "NYU": "Nyaung U",
    "NYW": "Monywa",
    "NZH": "Manzhouli Xijiao",
    "NZL": "扎兰屯机场",
    "OAG": "Orange",
    "OAJ": "Albert J. Ellis",
    "OAK": "Oakland International",
    "OAL": "Cacoal",
    "OAX": "Oaxaca International",
    "OBO": "Tokachi–Obihiro",
    "OCC": "Francisco de Orellana",
    "ODN": "Long Seridan",
    "ODY": "Oudomsay",
    "OEC": "Oecussi Airport",
    "OER": "Örnsköldsvik",
    "OGD": "Ogden-Hinckley",
    "OGG": "Kahului",
    "OGL": "Ogle",
    "OGN": "Yonaguni",
    "OGS": "Ogdensburg International",
    "OGU": "Ordu–Giresun",
    "OGX": "Ain Beida",
    "OHD": "Ohrid St. Paul the Apostle",
    "OHE": "Mohe Gulian",
    "OHS": "Sohar",
    "OIA": "Ourilândia do Norte Airport",
    "OIR": "Okushiri",
    "OIT": "Oita",
    "OKA": "Naha",
    "OKC": "Will Rogers World",
    "OKD": "Okadama",
    "OKE": "Okinoerabu",
    "OKI": "Oki",
    "OKJ": "Okayama",
    "OKR": "Yorke Island Airport",
    "OKU": "Mokuti Lodge Airport",
    "OLA": "Ørland Main Air Station",
    "OLB": "Olbia Costa Smeralda",
    "OLF": "L. M. Clayton",
    "OLP": "Olympic Dam",
    "OLX": "Olkiombo Airstrip",
    "OMA": "Eppley Airfield",
    "OMD": "Oranjemund",
    "OME": "Nome",
    "OMH": "Urmia",
    "OMO": "Mostar",
    "OMR": "Oradea International",
    "OMS": "Omsk Tsentralny",
    "OND": "Ondangwa Airport",
    "ONG": "Mornington Island",
    "ONJ": "Odate–Noshiro",
    "ONQ": "Caycuma",
    "ONS": "Onslow",
    "ONT": "Ontario International",
    "OOL": "Gold Coast",
    "OPO": "Porto",
    "OPP": "Salinópolis Airport",
    "OPS": "Sinop",
    "ORB": "Örebro",
    "ORD": "O Hare International",
    "ORF": "Norfolk International",
    "ORH": "Worcester Regional",
    "ORK": "Cork",
    "ORN": "Oran Es Sénia",
    "ORU": "Juan Mendoza",
    "ORY": "Paris Orly",
    "OSD": "Åre Östersund",
    "OSI": "Osijek",
    "OSL": "Oslo Airport, Gardermoen",
    "OSR": "Leoš Janáček Airport Ostrava",
    "OSS": "Osh",
    "OST": "Ostend-Bruges International",
    "OSY": "Namsos Airport, Høknesøra",
    "OTH": "Southwest Oregon Regional",
    "OTI": "Leo Wattimena",
    "OTP": "Henri Coandă International",
    "OTZ": "Ralph Wien Memorial",
    "OUA": "Ouagadougou",
    "OUD": "Angads",
    "OUL": "Oulu",
    "OUZ": "Tazadit",
    "OVB": "Tolmachevo",
    "OVD": "Asturias",
    "OWB": "Owensboro–Daviess County Regional",
    "OXB": "Osvaldo Vieira International",
    "OYE": "Oyem",
    "OZC": "Labo",
    "OZG": "Zagora",
    "OZZ": "Ouarzazate",
    "PAC": "Albrook Marcos A. Gelabert International",
    "PAD": "Paderborn Lippstadt",
    "PAE": "Paine Field Everett",
    "PAF": "Pakuba Airfield",
    "PAG": "Pagadian",
    "PAH": "Barkley Regional",
    "PAP": "Toussaint Louverture International",
    "PAS": "Paros National",
    "PAT": "Jay Prakash Narayan International",
    "PBC": "Puebla International",
    "PBG": "Plattsburgh International",
    "PBH": "Paro",
    "PBI": "Palm Beach International",
    "PBM": "Johan Adolf Pengel International",
    "PBO": "Paraburdoo",
    "PBR": "Puerto Barrios",
    "PBU": "Putao",
    "PBZ": "Plettenberg Bay",
    "PCL": "FAP Captain David Abensur Rengifo International",
    "PCN": "Picton Aerodrome",
    "PCP": "Príncipe Airport",
    "PCR": "Germán Olano",
    "PDA": "César Gaviria Trujillo",
    "PDG": "Minangkabau International",
    "PDL": "João Paulo II",
    "PDP": "Capitán de Corbeta Carlos A. Curbelo International",
    "PDS": "Piedras Negras International",
    "PDT": "Eastern Oregon Regional",
    "PDV": "Plovdiv",
    "PDX": "Portland International",
    "PEC": "Pelican Sea Plane Base",
    "PED": "Pardubice Airport",
    "PEE": "Perm International",
    "PEG": "San Francesco d Assisi",
    "PEI": "Matecaña International",
    "PEK": "Beijing Capital International",
    "PEM": "Padre Aldamiz International",
    "PEN": "Penang International",
    "PER": "Perth",
    "PET": "Pelotas International",
    "PEU": "Puerto Lempira",
    "PEV": "Pecs-Pogany Airport",
    "PEW": "Bacha Khan International",
    "PFB": "Lauro Kurtz",
    "PFO": "Paphos International",
    "PGA": "Page Municipal",
    "PGD": "Punta Gorda",
    "PGF": "Perpignan–Rivesaltes",
    "PGK": "Depati Amir",
    "PGZ": "Ponta Grossa A.Amilton Beraldo",
    "PHB": "Parnaíba-Prefeito Dr. João Silva Filho International",
    "PHC": "Port Harcourt International",
    "PHE": "Port Hedland International",
    "PHL": "Philadelphia International",
    "PHS": "Phitsanulok",
    "PHX": "Phoenix Sky Harbor International",
    "PIA": "General Wayne A. Downing Peoria International",
    "PIB": "Hattiesburg–Laurel Regional",
    "PIE": "St. Pete–Clearwater International",
    "PIH": "Pocatello Regional",
    "PIK": "Glasgow Prestwick",
    "PIN": "Parintins",
    "PIR": "Pierre Regional",
    "PIS": "Poitiers–Biard",
    "PIT": "Pittsburgh International",
    "PIU": "FAP Captain Guillermo Concha Iberico International",
    "PIX": "Pico",
    "PJA": "Pajala",
    "PJM": "Puerto Jiménez",
    "PKB": "Mid-Ohio Valley Regional",
    "PKE": "Parkes",
    "PKN": "Iskandar",
    "PKR": "Pokhara",
    "PKU": "Sultan Syarif Kasim II International",
    "PKX": "Beijing Daxing International Airport",
    "PKY": "Tjilik Riwut",
    "PKZ": "Pakse International",
    "PLJ": "Placencia",
    "PLM": "Sultan Mahmud Badaruddin II International",
    "PLN": "Pellston Regional",
    "PLO": "Port Lincoln",
    "PLQ": "Palanga International",
    "PLS": "Providenciales International",
    "PLW": "Mutiara SIS Al-Jufrie",
    "PLX": "Semey",
    "PLZ": "Port Elizabeth",
    "PMA": "Pemba",
    "PMC": "El Tepual International",
    "PMF": "Parma",
    "PMI": "Palma de Mallorca",
    "PMO": "Falcone–Borsellino",
    "PMR": "Palmerston North",
    "PMW": "Palmas",
    "PMY": "El Tehuelche",
    "PNA": "Pamplona",
    "PND": "Punta Gorda",
    "PNH": "Phnom Penh International",
    "PNI": "Pohnpei International",
    "PNK": "Supadio International",
    "PNL": "Pantelleria",
    "PNP": "Girua",
    "PNQ": "Pune",
    "PNR": "Pointe Noire",
    "PNS": "Pensacola International",
    "PNT": "Teniente Julio Gallardo",
    "PNZ": "Petrolina",
    "POA": "Salgado Filho International",
    "POG": "Port-Gentil International",
    "POJ": "Patos de Minas Airport",
    "POL": "Pemba",
    "POM": "Jacksons International",
    "POP": "Gregorio Luperón International",
    "POS": "Piarco International",
    "POZ": "Poznań–Ławica",
    "PPB": "Presidente Prudente",
    "PPG": "Pago Pago International",
    "PPK": "Petropavl",
    "PPN": "Guillermo León Valencia",
    "PPP": "Whitsunday Coast",
    "PPQ": "Kapiti Coast",
    "PPS": "Puerto Princesa International",
    "PPT": "Fa a ā International",
    "PPW": "Papa Westray",
    "PQC": "Phu Quoc International Airport",
    "PQI": "Northern Maine Regional Airport at Presque Isle",
    "PQQ": "Port Macquarie",
    "PRA": "General Justo José de Urquiza",
    "PRC": "Ernest A. Love Field",
    "PRG": "Václav Havel Airport Prague",
    "PRI": "Praslin Island",
    "PRM": "Portimão",
    "PRN": "Pristina International",
    "PRS": "Parasi Airport",
    "PSA": "Pisa International",
    "PSC": "Tri-Cities",
    "PSE": "Mercedita",
    "PSG": "Petersburg James A. Johnson",
    "PSM": "Portsmouth International Airport at Pease",
    "PSO": "Antonio Nariño",
    "PSP": "Palm Springs International",
    "PSR": "Abruzzo",
    "PSS": "Libertador General José de San Martín",
    "PSU": "Pangsuma",
    "PTG": "Polokwane International",
    "PTO": "Pato Branco Airport",
    "PTP": "Pointe-à-Pitre International",
    "PTQ": "Porto de Moz Airport",
    "PTX": "Pitalito",
    "PTY": "Tocumen International",
    "PUB": "Pueblo Memorial",
    "PUF": "Pau Pyrénées",
    "PUJ": "Punta Cana International",
    "PUK": "Pukarua Airport",
    "PUQ": "Presidente Carlos Ibáñez del Campo International",
    "PUS": "Gimhae International",
    "PUU": "Tres de Mayo",
    "PUW": "Pullman–Moscow Regional",
    "PUY": "Pula",
    "PVA": "El Embrujo",
    "PVC": "Provincetown Municipal",
    "PVD": "Rhode Island T. F. Green Int'l Airport",
    "PVG": "Shanghai Pudong International",
    "PVH": "Governador Jorge Teixeira de Oliveira International",
    "PVK": "Aktion National",
    "PVR": "Licenciado Gustavo Díaz Ordaz International",
    "PVU": "Provo Municipal",
    "PWM": "Portland International Jetport",
    "PWQ": "Pavlodar",
    "PXM": "Puerto Escondido International",
    "PXO": "Porto Santo",
    "PXU": "Pleiku",
    "PYE": "Tongareva Airport",
    "PYG": "Pakyong Airport",
    "PYT": "Paracatu Airport",
    "PZB": "Pietermaritzburg",
    "PZI": "Panzhihua Bao anying",
    "PZU": "Port Sudan New International",
    "PZY": "Piešťany",
    "QBC": "Bella Coola",
    "QGP": "Garanhuns Airport",
    "QIG": "Iguatu Airport",
    "QNS": "Canoas Airport",
    "QOW": "Sam Mbakwe",
    "QRO": "Querétaro International",
    "QRW": "Warri",
    "QSF": "Ain Arnat",
    "QSR": "Salerno / Pontecagnano Airport",
    "QSZ": "Yeerqiang Airport",
    "QUO": "Akwa Ibom",
    "RAB": "Rabaul",
    "RAE": "Arar Domestic",
    "RAH": "Rafha Domestic",
    "RAI": "Nelson Mandela International",
    "RAK": "Marrakesh Menara",
    "RAO": "Leite Lopes",
    "RAP": "Rapid City Regional",
    "RAR": "Rarotonga International",
    "RBA": "Rabat–Salé",
    "RBB": "Borba Airport",
    "RBQ": "Rurrenabaque",
    "RBR": "Rio Branco International",
    "RCB": "Richards Bay",
    "RCH": "Almirante Padilla",
    "RCM": "Richmond",
    "RCU": "Las Higueras",
    "RDD": "Redding Municipal",
    "RDM": "Roberts Field",
    "RDO": "Warsaw – Radom Airport",
    "RDP": "Kazi Nazrul Islam",
    "RDU": "Raleigh–Durham International",
    "RDZ": "Rodez–Aveyron",
    "REC": "Recife/Guararapes–Gilberto Freyre International",
    "REG": "Reggio Calabria",
    "REL": "Almirante Marcos A. Zar",
    "RER": "Retalhuleu",
    "RES": "Resistencia International",
    "RET": "Røst",
    "REU": "Reus",
    "REX": "General Lucio Blanco International",
    "RFD": "Chicago Rockford International",
    "RFP": "Raiatea",
    "RGA": "Hermes Quijada International",
    "RGI": "Rangiroa",
    "RGL": "Piloto Civil Norberto Fernández International",
    "RGN": "Yangon International",
    "RHD": "Las Termas",
    "RHI": "Rhinelander–Oneida County",
    "RHO": "Rhodes International",
    "RIA": "Santa Maria",
    "RIB": "Riberalta",
    "RIC": "Richmond International",
    "RIH": "Aeropuerto Internacional Cap. Scarlet Martínez",
    "RIS": "Rishiri",
    "RIW": "Riverton Regional",
    "RIX": "Riga International",
    "RIZ": "Rizhao Shanzihe",
    "RJA": "Rajahmundry",
    "RJB": "Rajbiraj Airport",
    "RJH": "Shah Makhdum",
    "RJK": "Rijeka",
    "RJL": "Logroño–Agoncillo",
    "RKA": "Aratika-Nord",
    "RKD": "Knox County Regional",
    "RKE": "Roskilde",
    "RKS": "Rock Springs–Sweetwater County",
    "RKT": "Ras Al Khaimah International",
    "RKV": "Reykjavík",
    "RKZ": "Shigatse Peace",
    "RLG": "Rostock–Laage",
    "RLK": "Bayannur Tianjitai",
    "RLO": "Valle del Conlara Airport",
    "RMA": "Roma",
    "RMF": "Marsa Alam International",
    "RMI": "Federico Fellini International",
    "RMO": "Chișinău International",
    "RMQ": "Taichung",
    "RMT": "Rimatara",
    "RMU": "Región de Murcia",
    "RNA": "Ulawa",
    "RNB": "Ronneby",
    "RNJ": "Yoron",
    "RNL": "Rennell/Tingoa",
    "RNN": "Bornholm",
    "RNO": "Reno–Tahoe International",
    "RNS": "Rennes–Saint-Jacques",
    "ROA": "Roanoke–Blacksburg Regional",
    "ROB": "Roberts International",
    "ROC": "Greater Rochester International",
    "ROI": "Roi Et",
    "ROK": "Rockhampton",
    "ROO": "Rondonópolis",
    "ROR": "Roman Tmetuchl International",
    "ROS": "Rosario – Islas Malvinas International",
    "ROT": "Rotorua Regional",
    "ROW": "Roswell International Air Center",
    "RPR": "Swami Vivekananda",
    "RQA": "Ruoqiang Loulan Airport",
    "RRG": "Sir Gaëtan Duval",
    "RRJ": "Jacarepaguá Airport",
    "RRR": "Raroia",
    "RRS": "Røros",
    "RSA": "Santa Rosa",
    "RSD": "Rock Sound International",
    "RSI": "Red Sea International Airport",
    "RST": "Rochester International",
    "RSU": "Yeosu",
    "RSW": "Southwest Florida International",
    "RTA": "Rotuma",
    "RTB": "Juan Manuel Gálvez International",
    "RTG": "Frans Sales Lega",
    "RTM": "Rotterdam The Hague",
    "RUH": "King Khalid International",
    "RUL": "Maavarulu Airport",
    "RUN": "Roland Garros",
    "RUR": "Rurutu",
    "RUT": "Rutland–Southern Vermont Regional",
    "RVD": "Rio Verde",
    "RVE": "Los Colonizadores",
    "RVK": "Rørvik Airport, Ryum",
    "RVN": "Rovaniemi",
    "RVV": "Raivavae",
    "RXS": "Roxas",
    "RYL": "Lower Zambezi Nat.Park Royal",
    "RZE": "Rzeszów–Jasionka",
    "RZV": "Rize–Artvin Airport",
    "SAB": "Juancho E. Yrausquin",
    "SAF": "Santa Fe Municipal",
    "SAG": "Shirdi",
    "SAI": "Siem Reap–Angkor International Airport",
    "SAL": "Monseñor Óscar Arnulfo Romero International",
    "SAN": "San Diego International",
    "SAP": "Ramón Villeda Morales International",
    "SAT": "San Antonio International",
    "SAV": "Savannah/Hilton Head International",
    "SAW": "Sabiha Gökçen International",
    "SBA": "Santa Barbara Municipal",
    "SBD": "San Bernardino International Airport",
    "SBH": "Gustaf III",
    "SBN": "South Bend International",
    "SBP": "San Luis Obispo County Regional",
    "SBR": "Saibai Island",
    "SBW": "Sibu",
    "SBY": "Salisbury–Ocean City–Wicomico Regional",
    "SBZ": "Sibiu International",
    "SCC": "Deadhorse",
    "SCE": "University Park",
    "SCF": "Phoenix Scottsdale Airport",
    "SCK": "Stockton Metropolitan",
    "SCL": "Comodoro Arturo Merino Benítez International",
    "SCN": "Saarbrücken",
    "SCO": "Aktau",
    "SCQ": "Santiago de Compostela",
    "SCR": "Scandinavian Mountains Airport",
    "SCV": "Suceava International",
    "SCY": "San Cristóbal",
    "SCZ": "Santa Cruz/Graciosa Bay/Luova",
    "SDD": "Lubango",
    "SDE": "Vicecomodoro Ángel de la Paz Aragonés",
    "SDF": "Louisville International",
    "SDJ": "Sendai",
    "SDK": "Sandakan",
    "SDL": "Sundsvall–Timrå",
    "SDN": "Sandane Airport, Anda",
    "SDP": "Sand Point",
    "SDQ": "Las Américas International",
    "SDR": "Santander",
    "SDU": "Santos Dumont",
    "SDY": "Sidney–Richland Municipal",
    "SEA": "Seattle–Tacoma International",
    "SEB": "Sabha",
    "SEN": "London Southend",
    "SET": "Serra Talhada Airport",
    "SEU": "Seronera Airstrip",
    "SEZ": "Seychelles International",
    "SFA": "Sfax–Thyna International",
    "SFB": "Orlando Sanford International",
    "SFG": "Grand Case-Espérance",
    "SFJ": "Kangerlussuaq",
    "SFL": "São Filipe",
    "SFN": "Sauce Viejo",
    "SFO": "San Francisco International",
    "SFT": "Skellefteå",
    "SGD": "Sønderborg",
    "SGF": "Springfield-Branson National",
    "SGN": "Tan Son Nhat International",
    "SGO": "St George",
    "SGU": "St. George Regional",
    "SGX": "Songea",
    "SGY": "Skagway",
    "SHA": "Shanghai Hongqiao International",
    "SHB": "Nakashibetsu",
    "SHC": "Shire",
    "SHD": "Shenandoah Valley Regional",
    "SHE": "Shenyang Taoxian International",
    "SHF": "Shihezi Huayuan",
    "SHI": "Shimojishima Airport",
    "SHJ": "Sharjah International",
    "SHL": "Shillong",
    "SHM": "Nanki–Shirahama",
    "SHO": "King Mswati III International",
    "SHR": "Sheridan County Airport",
    "SHS": "Shashi",
    "SHV": "Shreveport Regional",
    "SHW": "Sharurah Domestic",
    "SID": "Amílcar Cabral International",
    "SIF": "Simara",
    "SIG": "Fernando Luis Ribas Dominicci",
    "SIN": "Singapore Changi",
    "SIS": "Sishen",
    "SIT": "Sitka Rocky Gutierrez",
    "SJC": "San Jose International",
    "SJD": "Los Cabos International",
    "SJE": "Jorge Enrique González Torres",
    "SJI": "San Jose",
    "SJJ": "Sarajevo International",
    "SJK": "Aeroporto Internacional de São José dos Campos",
    "SJL": "Sao Gabriel",
    "SJO": "Juan Santamaría International",
    "SJP": "São José do Rio Preto",
    "SJT": "San Angelo Regional",
    "SJU": "Luis Muñoz Marín International",
    "SJW": "Shijiazhuang Zhengding International",
    "SJZ": "Sao Jorge",
    "SKB": "Robert L. Bradshaw International",
    "SKD": "Samarkand International",
    "SKG": "Thessaloniki International Airport Macedonia",
    "SKH": "Surkhet Airport",
    "SKN": "Stokmarknes Airport, Skagen",
    "SKO": "Sadiq Abubakar III International",
    "SKP": "Skopje Alexander the Great",
    "SKT": "Sialkot International",
    "SKU": "Skyros Island National",
    "SKZ": "Sukkur",
    "SLA": "Martín Miguel de Güemes International",
    "SLC": "Salt Lake City International",
    "SLI": "Solwezi",
    "SLK": "Adirondack Regional",
    "SLL": "Salalah",
    "SLM": "Salamanca",
    "SLN": "Salina Regional",
    "SLP": "San Luis Potosí International",
    "SLU": "George F. L. Charles",
    "SLZ": "Marechal Cunha Machado International",
    "SMA": "Santa Maria",
    "SMF": "Sacramento International",
    "SMI": "Samos International",
    "SMQ": "H. Asan",
    "SMR": "Simón Bolívar International",
    "SMS": "Sainte Marie",
    "SMT": "Sorriso",
    "SMX": "Santa Maria Public",
    "SNA": "John Wayne",
    "SNE": "Preguiça",
    "SNN": "Shannon",
    "SNO": "Sakon Nakhon",
    "SNP": "St. Paul Island",
    "SOC": "Adisumarmo International",
    "SOF": "Sofia",
    "SOG": "Sogndal Airport, Haukåsen",
    "SOJ": "Sørkjosen",
    "SON": "Santo-Pekoa International",
    "SOQ": "Domine Eduard Osok",
    "SOU": "Southampton",
    "SOW": "Show Low Regional",
    "SOY": "Stronsay",
    "SPC": "La Palma",
    "SPD": "Saidpur",
    "SPI": "Abraham Lincoln Capital",
    "SPN": "Saipan International",
    "SPP": "Menongue",
    "SPR": "San Pedro",
    "SPS": "Wichita Falls Municipal",
    "SPU": "Split",
    "SPX": "Sphinx International Airport",
    "SPY": "San Pédro",
    "SQD": "Shangrao Sanqingshan",
    "SQG": "Sintang",
    "SQJ": "Sanming Shaxian",
    "SRE": "Alcantarí International Airport",
    "SRG": "Ahmad Yani International",
    "SRP": "Stord Airport, Sørstokken",
    "SRQ": "Sarasota–Bradenton International",
    "SSA": "Deputado Luís Eduardo Magalhães International",
    "SSG": "Malabo International",
    "SSH": "Sharm el-Sheikh International",
    "SSJ": "Sandnessjøen Airport, Stokka",
    "STC": "St. Cloud Regional",
    "STI": "Cibao International",
    "STL": "St. Louis Lambert International",
    "STM": "Santarém-Maestro Wilson Fonseca",
    "STN": "London Stansted",
    "STR": "Stuttgart",
    "STS": "Charles M. Schulz–Sonoma County",
    "STT": "Cyril E. King",
    "STV": "Surat",
    "STX": "Henry E. Rohlsen",
    "SUB": "Juanda International",
    "SUF": "Lamezia Terme International",
    "SUG": "Surigao",
    "SUJ": "Satu Mare International",
    "SUN": "Friedman Memorial",
    "SUR": "Summer Beaver Airport",
    "SUV": "Nausori International",
    "SUX": "Sioux Gateway",
    "SVB": "Sambava",
    "SVC": "Grant County",
    "SVD": "Argyle International Airport",
    "SVG": "Stavanger Airport, Sola",
    "SVI": "Eduardo Falla Solano Airport",
    "SVJ": "Svolvær Airport, Helle",
    "SVO": "Sheremetyevo International",
    "SVP": "Kuito",
    "SVQ": "Seville",
    "SVU": "Savusavu",
    "SVX": "Koltsovo",
    "SWA": "Jieyang Chaoshan International",
    "SWF": "New York Stewart International Airport",
    "SWL": "San Vicente Airport",
    "SWO": "Stillwater Regional",
    "SWP": "Swakopmund Airport",
    "SWQ": "Sultan Muhammad Kaharuddin III",
    "SXB": "Strasbourg",
    "SXK": "Saumlaki Olilit",
    "SXM": "Princess Juliana International",
    "SXR": "Srinagar International",
    "SXV": "Salem",
    "SXZ": "Siirt",
    "SYD": "Sydney",
    "SYM": "Pu er Simao",
    "SYO": "Shonai",
    "SYR": "Syracuse Hancock International",
    "SYU": "Warraber Island Airport",
    "SYX": "Sanya Phoenix International",
    "SYY": "Stornoway",
    "SYZ": "Shiraz International",
    "SZA": "Soyo",
    "SZB": "Sultan Abdul Aziz Shah",
    "SZE": "Semera",
    "SZF": "Samsun-Çarşamba",
    "SZG": "Salzburg",
    "SZK": "Skukuza",
    "SZX": "Shenzhen Bao an International",
    "SZY": "Olsztyn-Mazury",
    "SZZ": "Solidarity Szczecin–Goleniów",
    "TAB": "Arthur Napoleon Raymond Robinson International",
    "TAC": "Daniel Z. Romualdez",
    "TAE": "Daegu International",
    "TAG": "Bohol-Panglao International",
    "TAK": "Takamatsu",
    "TAM": "Tampico International",
    "TAO": "Qingdao Jiaodong International Airport",
    "TAP": "Tapachula International",
    "TAS": "Tashkent International",
    "TAT": "Poprad–Tatry",
    "TAY": "Tartu",
    "TAZ": "Daşoguz",
    "TBB": "Dong Tac",
    "TBG": "Tabubil",
    "TBH": "Tugdan",
    "TBN": "Waynesville-St. Robert Regional",
    "TBO": "Tabora",
    "TBP": "Cap. FAP Pedro Canga Rodríguez",
    "TBS": "Tbilisi International",
    "TBT": "Tabatinga International",
    "TBU": "Fuaʻamotu International",
    "TBZ": "Tabriz International",
    "TCA": "Tennant Creek",
    "TCD": "Coposa",
    "TCG": "Tacheng",
    "TCO": "La Florida",
    "TCQ": "Coronel FAP Carlos Ciriani Santa Rosa International",
    "TCR": "Tuticorin",
    "TCZ": "Tengchong Tuofeng",
    "TDD": "Teniente Jorge Henrich Arauz",
    "TDK": "Taldykorgan",
    "TDX": "Trat",
    "TEE": "Cheikh Larbi Tebessi",
    "TEN": "Tongren Fenghuang",
    "TEQ": "Tekirdağ Çorlu",
    "TER": "Lajes Field",
    "TET": "Chingozi",
    "TEX": "Telluride Regional",
    "TEZ": "Tezpur",
    "TFF": "Tefé",
    "TFI": "Tufi",
    "TFL": "Teófilo Otoni Airport",
    "TFN": "Tenerife-North",
    "TFS": "Tenerife–South",
    "TFU": "Chengdu Tianfu International Airport",
    "TGC": "Tanjung Manis",
    "TGD": "Podgorica",
    "TGG": "Sultan Mahmud",
    "TGI": "Tingo María",
    "TGM": "Târgu Mureș International",
    "TGO": "Tongliao",
    "TGR": "Sidi Mahdi",
    "TGT": "Tanga",
    "TGU": "Toncontín International",
    "TGZ": "Tuxtla Gutiérrez International",
    "THD": "Thanh Hoa Tho Xuan",
    "THE": "Senador Petronio Portella",
    "THL": "Tachilek",
    "THS": "Sukhothai",
    "TIA": "Tirana International Airport Nënë Tereza",
    "TID": "Abdelhafid Boussouf Bou Chekif",
    "TIF": "Ta if Regional",
    "TIH": "Tikehau",
    "TIJ": "Tijuana International",
    "TIM": "Mozes Kilangin International",
    "TIN": "Tindouf",
    "TIP": "Tripoli International",
    "TIR": "Tirupati",
    "TIU": "Timaru",
    "TIV": "Tivat",
    "TIZ": "Tari",
    "TJA": "Capitan Oriel Lea Plaza",
    "TJH": "Toyooka",
    "TJK": "Tokat",
    "TJL": "Três Lagoas",
    "TJQ": "H.A.S. Hanandjoeddin International",
    "TJS": "Tanjung Harapan",
    "TKD": "Takoradi",
    "TKE": "Tenakee Seaplane Base",
    "TKG": "Radin Inten II International",
    "TKK": "Chuuk International",
    "TKN": "Tokunoshima",
    "TKP": "Takapoto",
    "TKQ": "Kigoma",
    "TKS": "Tokushima",
    "TKU": "Turku",
    "TKV": "Tatakoto",
    "TKX": "Takaroa",
    "TLC": "Toluca International",
    "TLE": "Toliara",
    "TLH": "Tallahassee International",
    "TLI": "Sultan Bantilan",
    "TLL": "Tallinn",
    "TLM": "Zenata – Messali El Hadj",
    "TLN": "Toulon–Hyères",
    "TLS": "Toulouse–Blagnac",
    "TLU": "Golfo de Morrosquillo",
    "TLV": "Ben Gurion",
    "TMC": "Tambolaka",
    "TME": "Gabriel Vargas Santos",
    "TMF": "Thimarafushi",
    "TMI": "Tumlingtar",
    "TMJ": "Termez",
    "TML": "Tamale",
    "TMM": "Toamasina",
    "TMP": "Tampere–Pirkkala",
    "TMR": "Aguenar – Hadj Bey Akhamok",
    "TMS": "São Tomé International",
    "TMT": "Trombetas",
    "TMW": "Tamworth",
    "TMX": "Timimoun",
    "TNA": "Jinan Yaoqiang International",
    "TNE": "New Tanegashima",
    "TNG": "Tangier Ibn Battouta",
    "TNH": "Tonghua Sanyuanpu",
    "TNJ": "Raja Haji Fisabilillah International",
    "TNO": "Tamarindo",
    "TNR": "Ivato International",
    "TOB": "Tobruk",
    "TOE": "Tozeur–Nefta International",
    "TOL": "Toledo Express",
    "TOS": "Tromsø Airport, Langnes",
    "TOY": "Toyama",
    "TPA": "Tampa International",
    "TPE": "Taiwan Taoyuan International",
    "TPP": "Cad. FAP Guillermo del Castillo Paredes",
    "TPQ": "Tepic International",
    "TPS": "Vincenzo Florio Airport Trapani–Birgi",
    "TQO": "Tulum International Airport",
    "TRC": "Torreón International",
    "TRD": "Trondheim Airport, Værnes",
    "TRE": "Tiree",
    "TRF": "Sandefjord Airport, Torp",
    "TRG": "Tauranga",
    "TRI": "Tri-Cities Regional",
    "TRK": "Juwata International",
    "TRN": "Turin",
    "TRR": "China Bay",
    "TRS": "Trieste – Friuli Venezia Giulia",
    "TRU": "FAP Captain Carlos Martínez de Pinillos International",
    "TRV": "Trivandrum International",
    "TRW": "Bonriki International",
    "TRZ": "Tiruchirappalli International",
    "TSA": "Taipei Songshan",
    "TSF": "Treviso",
    "TSJ": "Tsushima",
    "TSN": "Tianjin Binhai International",
    "TSR": "Timișoara Traian Vuia International",
    "TST": "Trang",
    "TSV": "Townsville",
    "TTA": "Tan Tan",
    "TTE": "Babullah",
    "TTJ": "Tottori",
    "TTN": "Trenton–Mercer",
    "TTQ": "Tortuguero",
    "TTU": "Sania Ramel",
    "TUB": "Tubuai – Mataura",
    "TUC": "Teniente General Benjamín Matienzo International",
    "TUF": "Tours Val de Loire",
    "TUG": "Tuguegarao",
    "TUI": "Turaif Domestic",
    "TUK": "Turbat International",
    "TUL": "Tulsa International",
    "TUN": "Tunis–Carthage International",
    "TUO": "Taupo",
    "TUP": "Tupelo Regional",
    "TUS": "Tucson International",
    "TUU": "Tabuk Regional",
    "TVC": "Cherry Capital",
    "TVF": "Thief River Falls Regional",
    "TVS": "Tangshan Sannühe",
    "TVU": "Matei",
    "TVY": "Dawei",
    "TWC": "Tumxuk Tangwangcheng Airport",
    "TWF": "Magic Valley Regional",
    "TWT": "Sanga-Sanga",
    "TWU": "Tawau",
    "TXK": "Texarkana Regional",
    "TXN": "Huangshan Tunxi International",
    "TYF": "Torsby",
    "TYL": "Capitán FAP Víctor Montes Arias International",
    "TYN": "Taiyuan Wusu International",
    "TYR": "Tyler Pounds Regional",
    "TYS": "McGhee Tyson",
    "TZA": "Belize City Municipal",
    "TZL": "Tuzla International",
    "TZX": "Trabzon",
    "UAH": "Ua Huka",
    "UAK": "Narsarsuaq",
    "UAP": "Ua Pou",
    "UAQ": "Domingo Faustino Sarmiento",
    "UAS": "Samburu",
    "UBA": "Uberaba",
    "UBJ": "Yamaguchi Ube",
    "UBN": "Chinggis Khaan International Airport",
    "UBP": "Ubon Ratchathani",
    "UCB": "Ulanqab Jining",
    "UDI": "Uberlandia",
    "UDR": "Maharana Pratap",
    "UEL": "Quelimane",
    "UEO": "Kumejima",
    "UET": "Quetta International",
    "UFA": "Ufa International",
    "UGC": "Urgench International",
    "UIB": "El Caraño",
    "UIH": "Phu Cat",
    "UIN": "Quincy Regional",
    "UIO": "Mariscal Sucre International",
    "UKA": "Ukunda",
    "UKB": "Kobe",
    "UKK": "Oskemen",
    "ULG": "Ölgii",
    "ULH": "Prince Abdul Majeed bin Abdulaziz Domestic",
    "ULO": "Ulaangom",
    "ULP": "Quilpie",
    "ULZ": "Donoi",
    "UME": "Umeå",
    "UNA": "Una-Comandatuba Airport",
    "UNG": "Kiunga",
    "UNK": "Unalakleet",
    "UNN": "Ranong",
    "UPG": "Sultan Hasanuddin International",
    "UPN": "Uruapan International",
    "URA": "Oral Ak Zhol",
    "URC": "Ürümqi Diwopu International",
    "URG": "Ruben Berta International",
    "URT": "Surat Thani International",
    "URY": "Gurayat Domestic",
    "USA": "Concord Regional",
    "USH": "Ushuaia – Malvinas Argentinas International",
    "USM": "Samui",
    "USN": "Ulsan",
    "USU": "Francisco B. Reyes",
    "UTH": "Udon Thani International",
    "UTN": "Upington",
    "UTP": "U-Tapao International",
    "UTT": "Mthatha",
    "UVE": "Ouvéa",
    "UVF": "Hewanorra International",
    "UVI": "Uniao da Vitoria Airport",
    "UYN": "Yulin Yuyang",
    "UYU": "Uyuni",
    "VAA": "Vaasa",
    "VAG": "Major Brigadeiro Trompowsky",
    "VAI": "Vanimo",
    "VAM": "Villa International",
    "VAN": "Van Ferit Melen",
    "VAO": "Suavanao",
    "VAR": "Varna",
    "VAS": "Sivas",
    "VAV": "Vavaʻu International",
    "VAW": "Vardø Airport, Svartnes",
    "VBA": "Ann",
    "VBP": "Bokpyin",
    "VBV": "Vanuabalavu",
    "VBY": "Visby",
    "VCA": "Can Tho International",
    "VCE": "Venice Marco Polo",
    "VCL": "Chu Lai International",
    "VCP": "Viracopos International",
    "VCS": "Con Dao",
    "VCT": "Victoria Regional",
    "VDC": "Vitória da Conquista",
    "VDE": "El Hierro",
    "VDH": "Dong Hoi",
    "VDM": "Gobernador Edgardo Castello",
    "VDO": "Van Don International Airport",
    "VDS": "Vadsø",
    "VDZ": "Valdez",
    "VEL": "Vernal Regional",
    "VER": "Veracruz International",
    "VFA": "Victoria Falls International",
    "VGA": "Vijayawada",
    "VGO": "Vigo–Peinador",
    "VGZ": "Villa Garzón",
    "VHC": "Saurimo",
    "VHM": "Vilhelmina",
    "VIE": "Vienna International Airport",
    "VII": "Vinh International",
    "VIJ": "Virgin Gorda",
    "VIL": "Dakhla",
    "VIT": "Vitoria",
    "VIX": "Eurico de Aguiar Salles",
    "VKG": "Rach Gia",
    "VKO": "Vnukovo International",
    "VLC": "Valencia",
    "VLD": "Valdosta Regional",
    "VLI": "Bauerfield International",
    "VLL": "Valladolid",
    "VLN": "Arturo Michelena International",
    "VNO": "Vilnius",
    "VNS": "Lal Bahadur Shastri International",
    "VNX": "Vilankulo",
    "VOG": "Volgograd International",
    "VOL": "Nea Anchialos National",
    "VPE": "Ondjiva Pereira",
    "VPG": "Vipingo",
    "VPS": "Destin–Fort Walton Beach",
    "VPY": "Chimoio",
    "VQS": "Antonio Rivera Rodríguez",
    "VRB": "Vero Beach Regional",
    "VRC": "Virac",
    "VRL": "Vila Real",
    "VRN": "Verona Villafranca",
    "VSA": "Villahermosa International",
    "VSE": "Viseu",
    "VST": "Stockholm Västerås",
    "VTE": "Wattay International",
    "VTZ": "Visakhapatnam",
    "VUP": "Alfonso López Pumarejo",
    "VVC": "La Vanguardia",
    "VVI": "Viru Viru International",
    "VVO": "Vladivostok International",
    "VVZ": "Takhamalt",
    "VXC": "Lichinga",
    "VXE": "Cesária Évora",
    "VXO": "Växjö Småland",
    "WAE": "Wadi al-Dawasir Domestic",
    "WAG": "Whanganui Airport",
    "WAW": "Warsaw Chopin",
    "WBM": "Wapenamanda",
    "WDH": "Hosea Kutako International",
    "WDS": "Shiyan Wudangshan",
    "WEF": "Weifang",
    "WEH": "Weihai Dashuibo",
    "WEI": "Weipa",
    "WGA": "Wagga Wagga",
    "WGN": "Shaoyang Wugang",
    "WGP": "Umbu Mehang Kunda",
    "WHK": "Whakatane",
    "WIC": "Wick",
    "WIL": "Wilson",
    "WIN": "Winton",
    "WJR": "Wajir",
    "WJU": "Wonju",
    "WKJ": "Wakkanai",
    "WLG": "Wellington International",
    "WLS": "Hihifo",
    "WMI": "Warsaw Modlin",
    "WMN": "Maroantsetra",
    "WMT": "Zunyi Maotai Airport",
    "WMX": "Wamena",
    "WNH": "Wenshan Puzhehei",
    "WNN": "Wunnumin Lake Airport",
    "WNP": "Naga",
    "WNR": "Windorah",
    "WNZ": "Wenzhou Longwan International",
    "WOL": "Illawarra Regional",
    "WPR": "Capitan Fuentes Martinez",
    "WPU": "Guardiamarina Zañartu",
    "WRE": "Whangarei",
    "WRG": "Wrangell",
    "WRO": "Copernicus Airport Wrocław",
    "WRY": "Westray",
    "WRZ": "Weerawila",
    "WSK": "Chongqing Wushan Airport",
    "WSZ": "Westport",
    "WTB": "Brisbane West Wellcamp",
    "WUA": "Wuhai",
    "WUH": "Wuhan Tianhe International",
    "WUS": "Wuyishan",
    "WUT": "Xinzhou Wutaishan",
    "WUX": "Sunan Shuofang International",
    "WUZ": "Changzhoudao Airport",
    "WVB": "Walvis Bay",
    "WWK": "Wewak",
    "WXN": "Wanzhou Wuqiao",
    "WYA": "Whyalla",
    "WYS": "Yellowstone",
    "XAI": "Xinyang Minggang Airport",
    "XAP": "Chapecó",
    "XBE": "Bearskin Lake",
    "XCH": "Christmas Island",
    "XCR": "Châlons Vatry",
    "XFN": "Xiangyang Liuji",
    "XGR": "Kangiqsualujjuaq (Georges River)",
    "XIC": "Xichang Qingshan",
    "XIL": "Xilinhot",
    "XIY": "Xi an Xianyang International",
    "XKH": "Xieng Khouang",
    "XKS": "Kasabonika Airport",
    "XLB": "Lac Brochet",
    "XMH": "Manihi",
    "XMN": "Xiamen Gaoqi International",
    "XMY": "Yam Island Airport",
    "XNA": "Northwest Arkansas Regional",
    "XNN": "Xining Caojiabao International",
    "XPL": "Coronel Enrique Soto Cano Air Base",
    "XQP": "Quepos La Managua",
    "XRY": "Jerez",
    "XSC": "South Caicos",
    "XSI": "South Indian Lake",
    "XSP": "Seletar Airport",
    "XTG": "Thargomindah",
    "XTL": "Tadoule Lake",
    "XUZ": "Xuzhou Guanyin International",
    "XWA": "Williston Basin International Airport",
    "YAA": "Anahim Lake",
    "YAB": "Arctic Bay",
    "YAK": "Yakutat",
    "YAM": "Sault Ste. Marie",
    "YAP": "Yap International",
    "YAT": "Attawapiskat",
    "YAX": "Wapekeka Airport",
    "YAY": "St. Anthony",
    "YAZ": "Tofino/Long Beach",
    "YBB": "Kugaaruk",
    "YBC": "Baie-Comeau",
    "YBE": "Uranium City Airport",
    "YBG": "CFB Bagotville",
    "YBK": "Baker Lake",
    "YBL": "Campbell River",
    "YBP": "Yibin Caiba",
    "YBR": "Brandon Municipal",
    "YBT": "Brochet",
    "YBX": "Lourdes-de-Blanc-Sablon",
    "YCB": "Cambridge Bay",
    "YCD": "Nanaimo",
    "YCG": "West Kootenay Regional",
    "YCO": "Kugluktuk",
    "YCR": "Cross Lake (Charlie Sinclair Memorial)",
    "YCS": "Chesterfield Inlet",
    "YCU": "Yuncheng Guangong",
    "YCY": "Clyde River",
    "YDA": "Dawson City",
    "YDF": "Deer Lake Regional",
    "YDP": "Nain",
    "YEG": "Edmonton International",
    "YEI": "Yenişehir",
    "YEK": "Arviat",
    "YER": "Fort Severn Airport",
    "YEV": "Inuvik (Mike Zubko)",
    "YFA": "Fort Albany",
    "YFB": "Iqaluit",
    "YFC": "Fredericton International",
    "YFO": "Flin Flon",
    "YFS": "Fort Simpson",
    "YGG": "Ganges Harbor",
    "YGJ": "Miho-Yonago",
    "YGL": "La Grande Rivière",
    "YGO": "Gods Lake Narrows",
    "YGP": "Michel-Pouliot Gaspé",
    "YGR": "Îles-de-la-Madeleine",
    "YGT": "Igloolik",
    "YGW": "Kuujjuarapik",
    "YGX": "Gillam",
    "YGZ": "Grise Fiord",
    "YHD": "Dryden Regional",
    "YHI": "Ulukhaktok/Holman",
    "YHK": "Gjoa Haven",
    "YHM": "John C. Munro Hamilton International",
    "YHO": "Hopedale",
    "YHP": "Poplar Hill Airport",
    "YHR": "Chevery",
    "YHS": "Sechelt",
    "YHU": "Montreal Saint-Hubert Longueuil",
    "YHY": "Hay River/Merlyn Carter",
    "YHZ": "Halifax Stanfield International",
    "YIA": "Yogyakarta International Airport",
    "YIC": "Yichun Mingyueshan",
    "YIE": "Arxan Yi'ershi",
    "YIF": "Saint-Augustin",
    "YIH": "Yichang Sanxia",
    "YIK": "Ivujivik",
    "YIN": "Yining",
    "YIO": "Pond Inlet",
    "YIV": "Island Lake",
    "YIW": "Yiwu",
    "YKA": "Kamloops",
    "YKF": "Region of Waterloo International",
    "YKG": "Kangirsuk",
    "YKH": "Yingkou Lanqi",
    "YKL": "Schefferville",
    "YKM": "Yakima Air Terminal",
    "YKO": "Hakkari–Yüksekova",
    "YKQ": "Waskaganish",
    "YKU": "Chisasibi",
    "YLC": "Kimmirut",
    "YLW": "Kelowna International",
    "YMM": "Fort McMurray International",
    "YMN": "Makkovik",
    "YMO": "Moosonee",
    "YMT": "Chibougamau/Chapais",
    "YNA": "Natashquan",
    "YNB": "Yanbu",
    "YNC": "Wemindji",
    "YNE": "Norway House",
    "YNJ": "Yanji Chaoyangchuan International",
    "YNL": "Points North Landing Airport",
    "YNO": "North Spirit Lake",
    "YNP": "Natuashish",
    "YNS": "Nemiscau",
    "YNT": "Yantai Penglai",
    "YNZ": "Yancheng Nanyang International",
    "YOC": "Old Crow",
    "YOH": "Oxford House",
    "YOJ": "High Level",
    "YOL": "Yola",
    "YOW": "Ottawa Macdonald–Cartier International",
    "YPA": "Prince Albert (Glass Field) Airport",
    "YPH": "Inukjuak",
    "YPJ": "Aupaluk",
    "YPL": "Pickle Lake Airport",
    "YPM": "Pikangikum",
    "YPO": "Peawanuck",
    "YPR": "Prince Rupert",
    "YPW": "Powell River",
    "YPX": "Puvirnituq",
    "YQB": "Québec City Jean Lesage International",
    "YQC": "Quaqtaq",
    "YQD": "The Pas",
    "YQG": "Windsor International",
    "YQL": "Lethbridge",
    "YQM": "Greater Moncton Roméo LeBlanc International",
    "YQQ": "CFB Comox",
    "YQR": "Regina International",
    "YQT": "Thunder Bay International",
    "YQU": "Grande Prairie",
    "YQX": "Gander International",
    "YQY": "Sydney/J.A. Douglas McCurdy",
    "YQZ": "Quesnel",
    "YRB": "Resolute Bay",
    "YRG": "Rigolet",
    "YRL": "Red Lake",
    "YRS": "Red Sucker Lake",
    "YRT": "Rankin Inlet",
    "YSB": "Sudbury",
    "YSF": "Stony Rapids Airport",
    "YSJ": "Saint John",
    "YSK": "Sanikiluaq",
    "YSO": "Postville",
    "YSQ": "Songyuan Chaganhu",
    "YST": "St. Theresa Point",
    "YTE": "Cape Dorset",
    "YTH": "Thompson",
    "YTL": "Big Trout Lake Airport",
    "YTQ": "Tasiujaq",
    "YTS": "Timmins/Victor M. Power",
    "YTY": "Yangzhou Taizhou International",
    "YTZ": "Billy Bishop Toronto City",
    "YUD": "Umiujaq",
    "YUL": "Montréal–Pierre Elliott Trudeau International",
    "YUM": "Yuma International",
    "YUS": "Yushu Batang",
    "YUT": "Naujaat",
    "YUX": "Hall Beach",
    "YUY": "Rouyn-Noranda",
    "YVB": "Bonaventure",
    "YVC": "La Ronge (Barber Field) Airport",
    "YVM": "Qikiqtarjuaq",
    "YVO": "Val-d Or",
    "YVP": "Kuujjuaq",
    "YVQ": "Norman Wells",
    "YVR": "Vancouver International",
    "YVZ": "Deer Lake",
    "YWB": "Kangiqsujuaq (Wakeham Bay)",
    "YWG": "Winnipeg James Armstrong Richardson International",
    "YWH": "Victoria Inner Harbour SPB",
    "YWK": "Wabush",
    "YWL": "Williams Lake",
    "YWS": "Whistler",
    "YXC": "Cranbrook/Canadian Rockies International",
    "YXE": "Saskatoon John G. Diefenbaker International",
    "YXH": "Medicine Hat",
    "YXJ": "Fort St. John",
    "YXL": "Sioux Lookout",
    "YXN": "Whale Cove",
    "YXP": "Pangnirtung",
    "YXS": "Prince George",
    "YXT": "Northwest Regional Airport Terrace-Kitimat",
    "YXU": "London International",
    "YXX": "Abbotsford International",
    "YXY": "Erik Nielsen Whitehorse International",
    "YYA": "Yueyang Sanhe Airport",
    "YYB": "North Bay/Jack Garland",
    "YYC": "Calgary International",
    "YYD": "Smithers",
    "YYE": "Fort Nelson",
    "YYF": "Penticton Regional",
    "YYG": "Charlottetown",
    "YYH": "Taloyoak",
    "YYJ": "Victoria International",
    "YYQ": "Churchill",
    "YYR": "CFB Goose Bay",
    "YYT": "St. John's International",
    "YYY": "Mont-Joli",
    "YYZ": "Toronto Pearson International",
    "YZF": "Yellowknife",
    "YZG": "Salluit",
    "YZP": "Sandspit",
    "YZS": "Coral Harbour",
    "YZT": "Port Hardy",
    "YZV": "Sept-Îles",
    "YZY": "Zhangye Ganzhou",
    "YZZ": "Trail",
    "ZAC": "York Landing",
    "ZAD": "Zadar",
    "ZAG": "Zagreb",
    "ZAL": "Pichoy Airport",
    "ZAM": "Zamboanga International",
    "ZAT": "Zhaotong",
    "ZAZ": "Zaragoza",
    "ZBF": "Bathurst",
    "ZBL": "Thangool",
    "ZCL": "Zacatecas International",
    "ZCO": "La Araucanía International",
    "ZEL": "Bella Bella (Campbell Island)",
    "ZEM": "Eastmain River",
    "ZFD": "Fond-du-Lac Airport",
    "ZGI": "Gods River",
    "ZGS": "La Romaine",
    "ZHA": "Zhanjiang",
    "ZHY": "Zhongwei Shapotou",
    "ZIA": "Zhukovsky International",
    "ZIH": "Ixtapa-Zihuatanejo International",
    "ZKE": "Kashechewan",
    "ZLO": "Playa de Oro International",
    "ZLT": "La Tabatière",
    "ZMT": "Masset",
    "ZNA": "Nanaimo Harbour",
    "ZNE": "Newman",
    "ZNZ": "Abeid Amani Karume International",
    "ZOS": "Cañal Bajo Carlos Hott Siebert",
    "ZPB": "Sachigo Lake",
    "ZQN": "Queenstown",
    "ZQZ": "Zhangjiakou Ningyuan",
    "ZRH": "Zürich Airport",
    "ZRJ": "Round Lake (Weagamow Lake)",
    "ZSA": "San Salvador",
    "ZSE": "Pierrefonds",
    "ZSJ": "Sandy Lake",
    "ZTA": "Tureia",
    "ZTB": "Tête-à-la-Baleine",
    "ZTH": "Zakynthos International",
    "ZTM": "Shamattawa",
    "ZUH": "Zhuhai Jinwan",
    "ZUM": "Churchill Falls",
    "ZVK": "Savannakhet",
    "ZWL": "Wollaston Lake Airport",
    "ZYI": "Zunyi Xinzhou",
    "ZYL": "Osmani International"
}